import Api from "@/services/Api";

export default {
  getAllOrders(id) {
    return Api()
      .get("GetOrderDetail?CustomerId=" + id)
      .catch(function(error) {
        if (error.response.status == 401) {
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },

  updateOrder(params) {
    //console.log(params);
    return Api()
      .post("UpdateOrder", params)
      .catch(function(error) {
        if (error.response.status == 401) {
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },
};

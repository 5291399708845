<template>
  <!-- Main Content Area -->
  <section class="et-content container-fluid">
    <div class="loaderss" v-if="showloading"></div>

    <div class="container">
      <div class="row">
        <!-- Menus -->
        <div class="col-sm-12 text-center mb-4">
          <h1>
            Best Laundry Deals & Offer
          </h1>
        </div>

        <div class="alert alert-danger" role="alert" v-if="isError">
          <h4 class="alert-heading">Oops!</h4>
          <p>
            Errr..Looks like things are not going in our favour! Error occured
          </p>
        </div>

        <!-- Card -->
        <div
          class="col-lg-4 col-md-12"
          v-for="item in data"
          v-bind:key="item.offerId"
        >
          <div class="mt-3 bg-white p-4 shadow-box pointer position-relative">
            <div class="flip-box menu">
              <div class="flip-box-inner">
                <div
                  class="
                    flip-box-front
                    text-center
                    p-4
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                  style="
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                  "
                >
                  <h1 class="font-weight-bold">
                    {{ item.offereName }}
                  </h1>
                  <a
                    href="#"
                    class="text-yellow text-decoration-none"
                    style="position: absolute; bottom: 0; left: 0; right: 0"
                    >View Details</a
                  >
                </div>
                <a href="https://app.primelaundry.co.uk">
                  <div class="flip-box-back p-3">
                    <div class="category-column text-center">
                      <!-- Item -->
                      <div>
                        <h2 class="font-weight-bold text-uppercase">
                          <span class="text-white">{{ item.offerDetail }}</span>
                        </h2>
                        <span
                          style="
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                          "
                        >
                          <a
                            href="https://app.primelaundry.co.uk"
                            class="
                              btn
                              shadow
                              bg-white
                              text-capitalize text-yellow
                            "
                            >Place Order</a
                          >
                          <h1
                            class="font-weight-bold text-uppercase"
                            style="margin-top: 6%"
                          >
                            <span class="strikethrough" style="color: red">
                              £{{ item.oldPrice }}
                            </span>
                            £{{ item.newPrice }}
                          </h1>
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Offers from "@/services/Offers";

export default {
  name: "Offers",
  data() {
    return {
      data: null,
      isError: false,
      showloading: false,
    };
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      title:
        "Best Deals and Discount Offers on Laundry & Dry Cleaning - Prime Laundry",
      meta: [
        {
          name: "description",
          content:
            "Prime Laundry provides best deals and amazing discount offers on wash & fold, dry cleaning and ironing services in London. Book now via our mobile apps or website",
        },
        {
          property: "og:title",
          content:
            "Best Deals and Discount Offers on Laundry & Dry Cleaning - Prime Laundry",
        },
        { property: "og:site_name", content: "Prime Laundry" },
        {
          property: "og:description",
          content:
            "Prime Laundry provides best deals and amazing discount offers on wash & fold, dry cleaning and ironing services in London. Book now via our mobile apps or website",
        },
        {
          property: "og:url",
          content: "https://app.primelaundry.co.uk/offers",
        },
      ],
    };
  },
  mounted() {
    this.getOffersList();
  },
  methods: {
    async getOffersList() {
      this.showloading = true;
      const response = await Offers.getAllOffers();
      if (response != undefined) {
        if (response.data.isSuccess) {
          //Get Offers List
          this.data = response.data.data1;
        } else {
          this.isError = true;
        }
      } else {
        this.isError = true;
        this.$notify.error({
          title: "Oops",
          message: "Something went wrong",
        });
      }

      this.showloading = false;
    },
  },
};
</script>

<style scoped>
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform: rotate(-21deg);
  -moz-transform: rotate(-21deg);
  -ms-transform: rotate(-21deg);
  -o-transform: rotate(-21deg);
  transform: rotate(-21deg);
}
</style>

<style>
section.container-fluid {
  padding-top: 50px !important;
}
</style>

<template>
  <section class="container-fluid et-add-restaurant position-relative">
    <div class="loaderss" v-if="showloading"></div>
    <div class="row justify-content-md-center">
      <customer-navigation></customer-navigation>

      <div class="col-md-8" style="margin-top: 1%">
        <div class="row">
          <!-- Change Password -->
          <div class="col-md-10 section-heading text-center">
            <h2>Change Password</h2>
          </div>
        </div>

        <form class="w-100 add-form mt-3" v-on:submit.prevent="changePassword">
          <div class="content-group">
            <div class="row">
              <div class="col-md-6" v-if="!provider_name">
                <!--Hide old password-->
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize"
                    >Old Password *</label
                  >

                  <div class="input-group mb-3">
                    <input
                      :type="passwordFieldType"
                      autocomplete="new-password"
                      class="form-control bg-white shadow-box"
                      placeholder="Old Password"
                      required
                      v-model="oldPassword"
                    />
                    <div class="input-group-prepend">
                      <span
                        @click="switchVisibility()"
                        class="input-group-text"
                        id="basic-addon1"
                        ><i
                          :class="
                            passwordFieldType == 'password'
                              ? 'fas fa-eye'
                              : 'fas fa-eye-slash'
                          "
                        ></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize"
                    >New Password *
                  </label>
                  <div class="input-group">
                    <input
                      :type="passwordFieldType1"
                      autocomplete="new-password"
                      class="form-control bg-white shadow-box"
                      placeholder="New Password"
                      v-model="newPassword"
                      v-bind:class="
                        has_minimum_lenth ? 'is-valid' : 'is-invalid'
                      "
                    />
                    <div class="input-group-prepend">
                      <span
                        @click="switchVisibility(1)"
                        class="input-group-text"
                        id="basic-addon1"
                        ><i
                          :class="
                            passwordFieldType1 == 'password'
                              ? 'fas fa-eye'
                              : 'fas fa-eye-slash'
                          "
                        ></i
                      ></span>
                    </div>
                  </div>

                  <small
                    v-if="has_minimum_lenth ? false : true"
                    class="text-muted"
                    >Password must be at least 6 characters</small
                  >
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize"
                    >Confirm Password *
                  </label>
                  <div class="input-group mb-3">
                    <input
                      :type="passwordFieldType2"
                      autocomplete="new-password"
                      class="form-control bg-white shadow-box"
                      placeholder="Confirm Password"
                      v-model="confirmPassword"
                      v-bind:class="
                        confirmPassword == newPassword && confirmPassword != ''
                          ? 'is-valid'
                          : 'is-invalid'
                      "
                    />
                    <div class="input-group-prepend">
                      <span
                        @click="switchVisibility(2)"
                        class="input-group-text"
                        id="basic-addon1"
                        ><i
                          :class="
                            passwordFieldType2 == 'password'
                              ? 'fas fa-eye'
                              : 'fas fa-eye-slash'
                          "
                        ></i
                      ></span>
                    </div>
                  </div>

                  <span
                    class="text-danger"
                    v-if="
                      newPassword != confirmPassword &&
                      confirmPassword.length > 0 &&
                      confirmPassword != ''
                        ? true
                        : false
                    "
                    >*password does not match
                  </span>
                </div>
              </div>
            </div>

            <div>
              <input
                :disabled="
                  newPassword == confirmPassword && has_minimum_lenth
                    ? false
                    : true
                "
                type="submit"
                class="btn shadow mt-3 bg-yellow text-capitalize text-white"
                value="Change Password"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import TokenExpireLogin from "@/services/TokenExpireLogin";
import CustomerNavigation from "@/core/CustomerNavigation.vue";
import UpdateCustomer from "@/services/UpdateCustomer";
import Vue from "vue";
import store from "../store";

export default {
  name: "ChangePassword",
  components: {
    CustomerNavigation,
  },
  data() {
    return {
      showloading: false,
      passwordFieldType: "password",
      passwordFieldType1: "password",
      passwordFieldType2: "password",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      has_minimum_lenth: false,

      provider_name: "",

      firstname: "",
      lastname: "",
      password: "",
      address1: "",
      address2: "",
      email: "",
      id: "",
      mobileIsd: "44",
      mobileNo: "",
      postCode: "",
      referFriend: "",
      totalOrder: 0,
    };
  },
  mounted() {
    const logintype = this.$store.getters.getLoginType;
    if (logintype != undefined) {
      this.provider_name = Vue.CryptoJS.AES.decrypt(
        logintype["providerName"],
        Vue.prototype.$secretKey
      ).toString(Vue.CryptoJS.enc.Utf8);
    } // if User logged with SSO then hide old password
    const userData = this.$store.getters.getUser;

    this.firstname = userData["firstname"];
    this.lastname = userData["lastname"];
    this.address1 = userData["address1"];
    this.address2 = userData["address2"];
    this.password = userData["password"];
    // this.mobileIsd = userData["mobileNo"].split("-")[0].replace("+", "").trim(); //get ISD code
    this.email = Vue.CryptoJS.AES.decrypt(
      userData["email"],
      Vue.prototype.$secretKey
    ).toString(Vue.CryptoJS.enc.Utf8);
    // this.mobileNo = userData["mobileNo"].split("-")[1]; //get phone number
    if (
      userData["mobileNo"] != "" &&
      userData["mobileNo"] != null &&
      userData["mobileNo"] != "null"
    ) {
      this.mobileIsd = userData["mobileNo"]
        .split("-")[0]
        .replace("+", "")
        .trim();
      this.mobileNo = userData["mobileNo"].split("-")[1]; //get phone number
    } else {
      this.mobileIsd = "44";
      this.mobileNo = "";
    }
    this.postCode = userData["postCode"];
    this.id = Number(
      Vue.CryptoJS.AES.decrypt(
        userData["id"],
        Vue.prototype.$secretKey
      ).toString(Vue.CryptoJS.enc.Utf8)
    );
    (this.referFriend = userData["referFriendCode"]),
      (this.totalOrder = userData["totalOrder"]);
  },

  methods: {
    switchVisibility(id) {
      if (id == 1) {
        this.passwordFieldType1 =
          this.passwordFieldType1 === "password" ? "text" : "password";
      } else if (id == 2) {
        this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password";
      } else {
        this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
      }
    },
    async changePassword() {
      this.showloading = true;
      const userData = this.$store.getters.getUser;
      const response = await UpdateCustomer.updatePassword({
        id: Number(
          Vue.CryptoJS.AES.decrypt(
            userData["id"],
            Vue.prototype.$secretKey
          ).toString(Vue.CryptoJS.enc.Utf8)
        ),
        username: Vue.CryptoJS.AES.decrypt(
          userData["email"],
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8),
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        providername: this.provider_name,
      });

      if (response) {
        if (response.data.isSuccess) {
          this.$notify({
            title: "Success",
            message: "Password Changed",
            type: "success",
          });

          //Set user in store

          const user = {
            firstname: this.firstname,
            lastname: this.lastname,
            mobileNo: "+" + this.mobileIsd + "-" + this.mobileNo, //.replace("+44-", "")
            password: this.confirmPassword,
            address1: this.address1,
            address2: this.address2,
            email: this.$CryptoJS.AES.encrypt(
              this.email,
              Vue.prototype.$secretKey
            ).toString(),
            id: this.$CryptoJS.AES.encrypt(
              this.id.toString(),
              Vue.prototype.$secretKey
            ).toString(),
            postCode: this.postCode,
            totalOrder: this.totalOrder,
            referFriendCode: this.referFriend,
          };

          //commit data in store
          store.commit("setUser", user);

          this.oldPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Invalid Old Password",
          });

          this.oldPassword = "";
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response != undefined) {
            if (response.data.isSuccess) {
              this.updateProfile();
            }
          } else {
            this.$root.$emit("checkPassword");
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });

          this.oldPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
        }
      }

      this.showloading = false;
    },

    passwordValid() {
      if (this.has_minimum_lenth) {
        return true;
      } else return false;
    },
  },
  watch: {
    newPassword() {
      this.has_minimum_lenth = this.newPassword.length >= 6;
    },
  },
};
</script>

<style scoped>
section.container-fluid {
  padding: 0px 0px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 100px;
}
</style>

<template>
  <section class="container-fluid et-add-restaurant position-relative">
    <div class="loaderss" v-if="showloading"></div>

    <div class="row justify-content-md-center">
      <customer-navigation></customer-navigation>

      <div class="col-md-8 overflow-hidden" style="margin-top: 1%">
        <div class="row">
          <!-- Account -->
          <div class="col-md-10 section-heading text-center">
            <h2>My Account</h2>
          </div>
        </div>

        <form class="w-100 add-form mt-3" v-on:submit.prevent="updateProfile">
          <div class="content-group">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">First Name *</label>
                  <input
                    type="text"
                    autocomplete="on"
                    disabled="disabled"
                    v-model="firstname"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">Last Name *</label>
                  <input
                    type="text"
                    autocomplete="on"
                    disabled="disabled"
                    v-model="lastname"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">Email Id *</label>
                  <input
                    type="email"
                    autocomplete="on"
                    disabled="disabled"
                    v-model="email"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">Postcode *</label>
                  <input
                    type="text"
                    autocomplete="on"
                    v-model="postCode"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="Post Code"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize"
                    >Address Line1 *</label
                  >
                  <input
                    type="text"
                    autocomplete="on"
                    v-model="address1"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="Address"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">
                    Address Line 2</label
                  >
                  <input
                    type="text"
                    autocomplete="on"
                    v-model="address2"
                    class="form-control bg-white shadow-box"
                    placeholder="Extra details"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-4">
                  <label class="text-muted text-capitalize">Mobile No *</label>
                  <div
                    class="row"
                    style="padding-right: 15px; padding-left: 15px"
                  >
                    <input
                      type="tel"
                      autocomplete="on"
                      disabled="disabled"
                      value="+"
                      class="form-control bg-white shadow-box col-1 inputLeft"
                      :class="validateIsd(mobileIsd) ? 'valid' : 'invalid'"
                    />
                    <input
                      type="tel"
                      autocomplete="on"
                      v-model="mobileIsd"
                      :class="validateIsd(mobileIsd) ? 'valid' : 'invalid'"
                      :maxlength="3"
                      class="form-control bg-white shadow-box col-2 inputRight"
                      placeholder="ISD"
                    /><!--type="number"-->
                    <input
                      type="number"
                      required
                      autocomplete="on"
                      v-model="mobileNo"
                      class="form-control bg-white shadow-box col-9"
                      placeholder="Mobile No"
                      v-bind:class="
                        (this.mobileNo.length == 10 ||
                          this.mobileNo.length == 11) &&
                        validatePhone(mobileNo)
                          ? 'is-valid'
                          : 'is-invalid'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <input
                :disabled="
                  this.validateIsd(this.mobileIsd) &&
                  this.validatePhone(this.mobileNo) &&
                  (mobileNo.length == 10 || mobileNo.length == 11)
                    ? false
                    : true
                "
                type="submit"
                class="btn shadow mt-3 bg-yellow text-capitalize text-white"
                value="Update Profile"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import TokenExpireLogin from "@/services/TokenExpireLogin";
import Vue from "vue";
import CustomerNavigation from "@/core/CustomerNavigation.vue";
import UpdateCustomer from "@/services/UpdateCustomer";
import store from "../store/index";

export default {
  name: "Account",

  components: {
    CustomerNavigation,
  },
  data() {
    return {
      showloading: false,

      firstname: "",
      lastname: "",
      password: "",
      address1: "",
      address2: "",
      email: "",
      id: "",
      mobileIsd: "44",
      mobileNo: "",
      postCode: "",
      referFriend: "",
      totalOrder: 0,
    };
  },
  mounted() {
    this.$gtag.enable(false);
    //Pre fill user data from store
    const userData = this.$store.getters.getUser;

    this.firstname = userData["firstname"];
    this.lastname = userData["lastname"];
    this.address1 = userData["address1"];
    this.address2 = userData["address2"];
    this.password = userData["password"];
    if (
      userData["mobileNo"] != "" &&
      userData["mobileNo"] != null &&
      userData["mobileNo"] != "null"
    ) {
      this.mobileIsd = userData["mobileNo"]
        .split("-")[0]
        .replace("+", "")
        .trim();
      this.mobileNo = userData["mobileNo"].split("-")[1]; //get phone number
    } else {
      this.mobileIsd = "44";
      this.mobileNo = "";
    }
    //get ISD code
    this.email = Vue.CryptoJS.AES.decrypt(
      userData["email"],
      Vue.prototype.$secretKey
    ).toString(Vue.CryptoJS.enc.Utf8);

    this.postCode = userData["postCode"];
    this.id = Number(
      Vue.CryptoJS.AES.decrypt(
        userData["id"],
        Vue.prototype.$secretKey
      ).toString(Vue.CryptoJS.enc.Utf8)
    );
    (this.referFriend = userData["referFriendCode"]),
      (this.totalOrder = userData["totalOrder"]);
  },
  methods: {
    validateIsd(Isd) {
      let regex = /^[0-9]{2}$/;
      let regex1 = /^[0-9]{3}$/;
      let regex2 = /^[0-9]{1}$/;

      if (Isd.match(regex) || Isd.match(regex1) || Isd.match(regex2)) {
        return true;
      } else {
        return false;
      }
    },

    validatePhone(phone) {
      let regex = /^[0-9]{10}$/;
      let regex1 = /^[0-9]{11}$/;

      if (phone.match(regex) || phone.match(regex1)) {
        return true;
      } else {
        return false;
      }
    },

    async updateProfile() {
      this.showloading = true;
      if (this.mobileIsd == "") {
        this.mobileIsd = "44";
      }
      // const response1 = await UpdateCustomer.updateCustomer()
      // console.log(response1)
      const response = await UpdateCustomer.updateCustomer({
        id: this.id,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        mobileNo: "+" + this.mobileIsd + "-" + this.mobileNo, //"+44-"
        address1: this.address1,
        address2: this.address2,
        postCode: this.postCode,
      });
      console.log(response);

      if (response) {
        if (response.data.isSuccess) {
          this.$notify({
            title: "Updated",
            message: "Profile Updated Succesfully",
            type: "success",
          });

          //Set data in store

          const user = {
            firstname: this.firstname,
            lastname: this.lastname,
            mobileNo: "+" + this.mobileIsd + "-" + this.mobileNo, //.replace("+44-", "")
            password: this.password,
            address1: this.address1,
            address2: this.address2,
            email: this.$CryptoJS.AES.encrypt(
              this.email,
              Vue.prototype.$secretKey
            ).toString(),
            id: this.$CryptoJS.AES.encrypt(
              this.id.toString(),
              Vue.prototype.$secretKey
            ).toString(),
            postCode: this.postCode,
            totalOrder: this.totalOrder,
            referFriendCode: this.referFriend,
          };

          //commit data in store
          store.commit("setUser", user);
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response != undefined) {
            if (response.data.isSuccess) {
              console.log(response);
              this.updateProfile();
            }
          } else {
            this.$root.$emit("checkPassword");
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }

      this.showloading = false;
    },
  },
};
</script>

<style scoped>
section.container-fluid {
  padding: 0px 0px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 100px;
}
</style>

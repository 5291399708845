import Vue from "vue";
import Login from "@/services/Login";
import store from "../store/index";

export default {
  async tokenExpireLogin() {
    let userData = store.getters.getUser;
    if (userData.password != undefined) {
      let firstName = userData.firstname;
      let lastName = userData.lastname;
      let email = Vue.CryptoJS.AES.decrypt(
        userData.email,
        Vue.prototype.$secretKey
      ).toString(Vue.CryptoJS.enc.Utf8);
      if (userData.password == undefined) {
        console.log("hello")
        document.getElementById("loginModal").click();
      }
      let password = Vue.CryptoJS.AES.decrypt(
        userData.password,
        Vue.prototype.$secretKey
      ).toString(Vue.CryptoJS.enc.Utf8);
    
      let address1 = userData.address1;
      let address2 = userData.address2;
      let mobileNo = userData.mobileNo;
      let postCode = userData.postCode;

      //get provider name and provider id from local storage
      let loginData = store.getters.getLoginType;

      if (loginData != undefined) {
        let provider_name = Vue.CryptoJS.AES.decrypt(
          loginData.providerName,
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8);

        let provider_id = Vue.CryptoJS.AES.decrypt(
          loginData.providerId,
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8);

        let access_token = Vue.CryptoJS.AES.decrypt(
          loginData.accessToken,
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8);

        const response = await Login.postCustomer({
          firstname: firstName,
          lastname: lastName,
          email: email,
          mobileNo: mobileNo,
          address1: address1,
          address2: address2,
          postCode: postCode,
          plateform: "Web",
          loginDetails: {
            username: email,
            password: password,
          },

          providerName: provider_name,
          providerId: provider_id,
          accessToken: access_token,
        });

        if (response.data.isSuccess) {
          console.log(response.data)
          const token = response.data.data2
          const encryptedToken = Vue.CryptoJS.AES.encrypt(
            token,
            Vue.prototype.$secretKey
          ).toString();
          store.commit("setToken", encryptedToken)
          return response;
        }
      } else {
        const response = await Login.getLogin({
          username: email,
          password: password,
        });
        if (response.data.isSuccess) {
          const token = response.data.data1
          const encryptedToken = Vue.CryptoJS.AES.encrypt(
            token,
            Vue.prototype.$secretKey
          ).toString();
          store.commit("setToken", encryptedToken)
          return response;
        }
      }
    }
  },
};

<template>
  <div>
    <div class="loaderss" v-if="showloading"></div>
  </div>
</template>

<script>
import Vue from "vue";
import Checkout from "@/services/checkout";
export default {
  name: "Services",
  data() {
    return {
      showloading: true,
    };
  },
  mounted() {
    // setTimeout(() => {
      this.$gtag.enable(true);
      if (localStorage.getItem("sessionId") != null) {
        this.confirmPayment();
      } else {
        this.$router.push("Orders");
      }
    // }, 5000);
  },
  methods: {
    async confirmPayment() {
      const response = await Checkout.confirmPayment({
        sessionId: Vue.CryptoJS.AES.decrypt(
          localStorage.getItem("sessionId"),
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8),
        orderId: Number(
          Vue.CryptoJS.AES.decrypt(
            localStorage.getItem("OrderId"),
            Vue.prototype.$secretKey
          ).toString(Vue.CryptoJS.enc.Utf8)
        ),
      });
      // to be uncommented later
      this.$gtag.reportConversion({
        send_to: "AW-478064958/P0sNCKzm-NMCEL7i-uMB", // the custom tag to be reported
        value: 20,
        currency: "GBP",
        transaction_id: Number(
          Vue.CryptoJS.AES.decrypt(
            localStorage.getItem("OrderId"),
            Vue.prototype.$secretKey
          ).toString(Vue.CryptoJS.enc.Utf8)
        ),
      });
      window.fbq("track", "Purchase", { currency: "GBP", value: 20.0 });
      this.showloading = false;
      localStorage.setItem("OrderResponse", JSON.stringify(response.data));
      if (response.data.isSuccess) {
        this.$store.commit("setTotalOrder");
        console.log("success");
      } else {
        this.$notify.error({
          title: "Oops",
          message: "Could not process your order.",
        });
        console.log("error");
      }
      localStorage.removeItem("sessionId");
      localStorage.removeItem("OrderId");
      this.$router.push("Orders");
    },
  },
};
</script>
<template>
  <footer class="et-footer">
    <div class="container">
      <div
        class="row text-white justify-content-md-center"
        style="letter-spacing: 0.5px"
      >
        <div class="col-lg-4 col-md-6 mt-3">
          <div class="mb-4">
            <a href="/">
              <img src="static/images/logo_white.svg" style="width: 208px"
            /></a>
          </div>

          <div class="footer-social">
            <div class="allIcons mb-2">
              <a
                href="https://www.facebook.com/primelaundryuk/"
                target="_blank"
                class="d-inline-block rounded-pill fb-icon mr-2"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a
                href="https://twitter.com/laundry_prime"
                target="_blank"
                class="d-inline-block rounded-pill tw-icon mr-2"
                ><i class="fab fa-twitter"></i
              ></a>
              <a
                href="https://www.instagram.com/primelaundryuk/"
                target="_blank"
                class="d-inline-block rounded-pill ig-icon mr-2"
                ><i class="fab fa-instagram"></i
              ></a>
              <a
                href="https://www.linkedin.com/company/prime-laundry/"
                target="_blank"
                class="d-inline-block rounded-pill li-icon mr-2"
                ><i class="fab fa-linkedin-in"></i
              ></a>
              <a
                href="https://www.youtube.com/channel/UCEVbQVAFha9fqcqBr3h5fMA"
                target="_blank"
                class="d-inline-block rounded-pill yt-icon mr-2"
                ><i class="fab fa-youtube"></i
              ></a>
              <a
                href="https://www.pinterest.co.uk/primelaundryuk/"
                target="_blank"
                class="d-inline-block rounded-pill pi-icon mr-2"
                ><i class="fab fa-pinterest-p"></i
              ></a>
            </div>
            <label class="socialText">FOLLOW US ON SOCIAL MEDIA</label>
          </div>
          <div class="contact-info align-items-center mb-3">
            <div>
              <div class="mb-2 d-flex align-items-center">
                <i
                  class="d-inline-block mr-2 fas fa-clock text-white"
                  style="font-size: 20px"
                >
                </i>
                <p class="paraText text-white m-0">
                  7 days a week 7:00 AM to 11:00 PM
                </p>
              </div>
              <div class="d-flex align-items-center">
                <i
                  class="d-inline-block ml-1 mr-2 fas fa-mobile-alt text-white"
                  style="font-size: 20px"
                >
                </i>
                <p class="paraText text-white m-0">
                  <a
                    href="tel:+91 0123456789"
                    style="text-decoration: none; color: white"
                    >(+44) 7984379914</a
                  >
                </p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <label class="dicoverApp" style="font-size: 20px"
                >Discover our app</label
              >
            </div>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.arkinfosoft.primelaundry"
                target="_blank"
              >
                <img
                  src="static/images/google_play.svg"
                  alt="logo"
                  class="img-fluid mr-2"
              /></a>
              <a
                href="https://apps.apple.com/us/app/primelaundry/id1556047501#?platform=iphone"
                target="_blank"
              >
                <img
                  src="static/images/apple_store.svg"
                  alt="logo"
                  class="img-fluid mr-2"
              /></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mt-3">
          <h4 class="text-capitalize mb-4 font-weight-bold text-white">
            Our services
          </h4>
          <ul class="footer-nav nostyle p-0">
            <li v-for="link in serviceLinks" :key="link.href" class="pt-2 pb-1">
              <a :href="link.href">{{ link.text }}</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-6 mt-3">
          <h4 class="text-capitalize mb-4 font-weight-bold text-white">
            Quick Links
          </h4>
          <ul class="footer-nav nostyle p-0">
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'about-us/'">About us</a>
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'pricing/'">Pricing</a>
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'service/'">Our Services</a>
            </li>
            <li class="pt-2 pb-1"><a :href="websiteLink + 'faqs/'">FAQs</a></li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'privacy-policy/'">Privacy Policy</a>
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'terms-conditions/'"
                >Terms And Conditions</a
              >
            </li>
            <li class="pt-2 pb-1"><a :href="websiteLink + 'blog'">Blog</a></li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'career/'">Career</a>
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'all-locations/'">All Locations</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mt-3">
          <h4 class="text-capitalize mb-4 font-weight-bold text-white">
            Commercial Services
          </h4>
          <ul class="footer-nav nostyle p-0">
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'commercials/airbnb-cleaning-service/'"
                >Airbnb Laundry</a
              >
            </li>
            <li class="pt-2 pb-1">
              <a
                :href="
                  websiteLink + 'commercials/restaurants-laundry-services/'
                "
                >Restaurant Laundry</a
              >
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'commercials/workwear-laundry-services/'"
                >Workwear Laundry</a
              >
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'commercials/cafe-laundry-services/'"
                >Cafe Laundry</a
              >
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'commercials/hotel-laundry-services/'"
                >Hotel Laundry</a
              >
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'commercials/spa-laundry-services/'"
                >Spa Laundry</a
              >
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'commercials/gym-laundry-service/'"
                >Gym Laundry</a
              >
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'commercials/medical-laundry-service/'"
                >Medical Laundry</a
              >
            </li>
            <li class="pt-2 pb-1">
              <a :href="websiteLink + 'commercials/towel-cleaning-service/'"
                >Towel Laundry</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="border-secondary mt-4 mb-4" />
    <div class="container">
      <div class="mt-8">
        <p class="text-white text-center mb-0" style="font-size: 17px">
          <i class="far fa-copyright"></i> {{ new Date().getFullYear() }} All
          Rights Reserved. Designed by
          <a href="/" class="text-white">Prime Laundry</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { services } from '@/data'
const WEBSITE_LINK = 'https://primelaundry.co.uk/'

export default {
  name: 'Footer',

  computed: {
    websiteLink() {
      return WEBSITE_LINK
    },
    serviceLinks() {
      return [
        ...services,
        {
          text: 'View All Services',
          href: `${this.websiteLink}/service`,
        },
      ]
    },
  },
}
</script>

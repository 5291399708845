import Vue from "vue";
import VueRouter from "vue-router";
import Orders from "../views/Orders.vue";
import Services from "../views/Services.vue";
import Offers from "../views/Offers.vue";
import BookNow from "../views/BookNow.vue";
import Account from "../views/Account.vue";
import ChangePassword from "../views/ChangePassword.vue";
import ReferFriends from "../views/ReferFriends.vue";
import CheckOut from "../views/CheckOut.vue";
import CheckOut3 from "../views/Checkout3.vue";
import CheckOut2 from "../views/Checkout2.vue";
import ConfirmPayment from "../views/ConfirmPayment.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Pricing",
    name: "Pricing",
    component: Services,
    meta: {
      auth: false,
    },
  },
  {
    path: "/confirm-payment",
    name: "ConfirmPayment",
    component: ConfirmPayment,
    meta: {
      auth: false,
    },
  },
  {
    path: "/Orders/:status?",
    name: "Orders",
    component: Orders,
    meta: {
      auth: true,
    },
  },
  {
    path: "/offers",
    name: "Offers",
    component: Offers,
    meta: {
      auth: false,
    },
  },
  {
    path: "/CheckOut",
    name: "CheckOut",
    component: CheckOut,
    meta: {
      auth: false,
    },
  },
  {
    path: "/CheckoutPayment/:sessionId?",
    name: "CheckOut",
    component: CheckOut2,
    meta: {
      auth: false,
    },
  },
  {
    path: "/CheckOut3",
    name: "CheckOut3",
    component: CheckOut3,
    meta: {
      auth: false,
    },
  },
  {
    path: "/",
    alias: ["/book-now", "/book-now/:path?"],
    name: "BookNow",
    component: BookNow,
    meta: {
      auth: false,
    },
  },

  {
    path: "/ChangePassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      auth: true,
    },
  },
  {
    path: "/Account",
    name: "Account",
    component: Account,
    meta: {
      auth: true,
    },
  },
  {
    path: "/refer-friends",
    name: "ReferFriends",
    component: ReferFriends,
    meta: {
      auth: true,
    },
  },
  {
    path: "/DownloadApp",
    name: "download-app",
    component: () => import('@/views/DownloadApp.vue'),
    meta: {
      auth: false,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to && to.meta && to.meta.auth) {
    if (
      localStorage.getItem("isLogin") == true ||
      localStorage.getItem("isLogin") == "true"
    ) {
      next();
    } else {
      if (to.name !== "login") {
        if (to.name == "ReferFriends") {
          localStorage.setItem("refer-friend", true);
        }
        window.location.href = "/";
        return false;
      }
      next();
    }
  } else next();
  // ...
});

export default router;

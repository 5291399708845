<template>
  <div>
    <h1>Please give us your payment details:</h1>
    <card class='stripe-card'
      :class='{ complete }'
      stripe='pk_test_51HvnQcLd90slTD01oOoQm8BauUty54ta4rIjrkorrsOlR9eqAARfgw4mFb2xMESrHROjbHzEwDfHLb5UvZESBPW800bufRvQVg'
      :options='stripeOptions'
      @change='complete = $event.complete'
    />
    <button class='pay-with-stripe' @click='pay' :disabled='!complete'>Pay with credit card</button>
  </div>
</template>
 
<script>
import { Card, createToken } from 'vue-stripe-elements-plus'
 
export default {
  data () {
    return {
      complete: false,
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      }
    }
  },
 
  components: { Card },
 
  methods: {
    pay () {
      console.log("Pay")
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      createToken().then(data => console.log(data.token))
    }
  }
}
</script> 
 
<style>
.stripe-card {
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.stripe-card.complete {
  border-color: green;
}
</style> 
import Api from "@/services/Api";

export default {
  getLogin(params) {
    return Api()
      .post("Login", params)
      .catch(function(error) {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },

  insertCustomer(params) {
    return Api()
      .post("InsertCustomer", params)
      .catch(function(error) {
        if (error.response.status == 401) {
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },

  postCustomer(params) {
    return Api()
      .post("ManageSocialSign", params)
      .catch(function(error) {
        if (error.response.status == 401) {
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },

  forgotPassword(params) {
    return Api()
      .post("ForgotPassword", params)
      .catch(function(error) {
        if (error.response.status == 401) {
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },

  changePassword(params) {
    return Api()
      .post("ChangePassword", params)
      .catch(function(error) {
        if (error.response.status == 401) {
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },

  SendOTP(params) {
    return Api()
      .post("SendOTP", params)
      .catch(function(error) {
        if (error.response.status == 401) {
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },
};

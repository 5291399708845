import Api from "@/services/Api";


export default {
  // async updateCustomer() {
  //   const request = new Request("https://dd4.arkinfosoft.in/api/Doctor/Get_User?username=2356897845&password=11111111&app_version=1.5&OS=Windows", {
  //     method: "GET",
  //     headers: {
  //        "Access-Control-Allow-Origin": "*",
  //     },
      
  //   });
  //   const res = await fetch(request);
  //   console.log(res)
  //   const data = await res.json();
  //   console.log("res", data)
  //   return data;
  // },

   updateCustomer(params) {
    return Api().post("UpdateCustomer", params)
      .catch(function(error) {
        if (error.response.status == 401) {
          //console.log("In")
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      }); 
    },

  updatePassword(params) {
    return Api()
      .post("ChangeCustomerPassword", params)
      .catch(function(error) {
        if (error.response.status == 401) {
          localStorage.setItem("TokenExpire", true);
        } else if (error.request) {
          console.log(error.request);
        } else if (error.response) {
          console.log(error.response.data);
        } else {
          console.log("Error", error.message);
        }
      });
  },
};

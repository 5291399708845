import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import VueSession from "vue-session";
import Select from "vue-select2";

// import Select2 from "v-select2-component";
import VueGtag from "vue-gtag-conversion";

import VueMeta from "vue-meta";
Vue.use(VueMeta);
Vue.use(Select);

var options = {
  persist: true,
};

locale.use(lang);
Vue.use(Element);
Vue.use(VueSession, options);
// Vue.use(Select2);
Vue.use(VueGtag, {
  id: "AW-478064958", // Your Adwords ID
  enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: process.env.NODE_ENV !== 'production' (optional)
  debug: true, // Whether or not display console log debugs (optional)
});

Vue.prototype.$api = process.env.VUE_APP_API_URL //'https://admin.primelaundry.co.uk/'
Vue.prototype.$secretKey = "@rk !nf0soft K*y";
// Vue.prototype.$session = "";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import axios from "axios";
import store from "../store";
import Vue from "vue";

export default () => {
  const axiosInstant = axios.create({
    baseURL: Vue.prototype.$api + "api/Laundry/",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization:
        "Bearer " +
        Vue.CryptoJS.AES.decrypt(
          store.getters.getToken,
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8),
    },
  });

  axiosInstant.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error) {
        console.log(error);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstant;
};

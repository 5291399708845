import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import VueCryptojs from "vue-cryptojs";

Vue.use(Vuex);
Vue.use(VueCryptojs);

export default new Vuex.Store({
  state: {
    user: {
      firstname: "",
      lastname: "",
      address1: "",
      address2: "",
      email: "",
      id: "",
      mobileNo: "",
      postCode: "",
      referFriendCode: "",
      plateform: "Web",
      totalOrder: 0
    },
    token: "",
    postCodenew:"",
  },
  plugins: [createPersistedState()],
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setLoginType(state, loginType) {
      state.loginType = loginType;
    },
    setTotalOrder(state) {
      state.user.totalOrder = Number(state.user.totalOrder) + 1;
    },
    setPostcode(state, postCode) {
      state.postCodenew = postCode;
    },
    setAddress1(state, address1) {
      state.address1 = address1;
    },
    setAddress2(state, address2) {
      state.address2 = address2;
    }
  },
  actions: {},
  modules: {},
  getters: {
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    },
    getLoginType(state) {
      return state.loginType;
    },
    getPostcode(state) {
      return state.postCodenew;
    },
    getAddress1(state) {
      return state.address1;
    },
    getAddress2(state) {
      return state.address2;
    }
  },
});

<template>
  <section class="container-fluid et-popular-list position-relative">
    <div class="loaderss" v-if="showloading"></div>

    <div class="row justify-content-md-center">
      <customer-navigation></customer-navigation>

      <div class="col-md-8" style="margin-top: 1%">
        <div class="row">
          <!-- Current Orders -->
          <div class="col-md-10 section-heading text-center">
            <h2 class="text-uppercase">Current Orders</h2>
          </div>
          <div
            class="col-sm-12 col-lg-5"
            v-for="item in listOrders"
            v-bind:key="item.id"
          >
            <div class="popular-list-items">
              <!--Item -->
              <div class="popular-item shadow-box mt-5">
                <small
                  v-bind:class="changeColorCss(item.orderStatus)"
                  class="
                    et-badge
                    pl-2
                    pr-2
                    text-white
                    position-absolute
                    custom-badge
                  "
                  >{{ item.orderStatus }}</small
                >
                <div class="d-sm-flex align-items-center">
                  <div class="content w-100">
                    <a
                      v-if="
                        item.orderStatus == 'Awaiting Pickup' ||
                        item.orderStatus == 'InProgress'
                      "
                      href="#"
                      data-toggle="modal"
                      data-target="#updateOrderModal"
                      class="
                        position-absolute
                        text-decoration-none
                        wishlist
                        rounded-pill
                        text-white
                        icon-bg
                        bg-black
                      "
                      @click="UpdateOrderModal(item.id)"
                      ><i class="fas fa-edit"></i
                    ></a>

                    <div class="meta-row">
                      <div class="float-right">
                        <i class="icon-wallet bit-bold"></i>
                        £{{ item.preAmount }}
                        paid
                      </div>
                    </div>
                    <h4 class="font-weight-bold mt-2 mb-3">
                      <span
                        class="
                          text-reset text-capitalize text-decoration-none
                          link-hover
                        "
                        >{{ item.orderName }}</span
                      >
                    </h4>
                    <p class="description" style="padding-right: 19%">
                      {{ item.services }}
                    </p>
                    <hr />
                    <div class="row">
                      <div class="col-md-6 col-6">
                        <span>
                          <p class="mb-0 text-yellow">Pickup Time</p>
                          <p class="align-bottom mt-0 ml-0 description">
                            {{ item.pickupDate }} , <br />
                            {{ item.pickupTime }}
                          </p>
                        </span>
                      </div>

                      <div class="col-md-6 col-6">
                        <span>
                          <p class="mb-0 text-yellow">Delivery Time</p>
                          <p class="align-bottom mt-0 ml-0 description">
                            {{ item.dropOffDate }}, <br />
                            {{ item.dropOffTime }}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-10 text-center">
            <h4><p v-if="listOrders.length == 0">No Data Available</p></h4>
          </div>

          <!-- Completed Orders -->

          <div class="col-md-10 section-heading text-center mt-5">
            <h2 class="text-uppercase">Completed Orders</h2>
          </div>

          <div class="et-cuisines col-lg-10">
            <ul
              class="nav justify-content-center mt-4 w-100 nav-pills mb-5"
              id="cuisine-tab"
              role="tablist"
            >
              <li class="nav-item mt-3">
                <button
                  class="btn nav-link shadow-box"
                  v-bind:class="{ active: filter == 'Month' }"
                  @click="filterData('Month')"
                >
                  Month
                </button>
              </li>
              <li class="nav-item mt-3">
                <button
                  v-bind:class="{ active: filter == '3 Month' }"
                  @click="filterData('3 Month')"
                  class="btn nav-link shadow-box"
                >
                  3 Month
                </button>
              </li>
              <li class="nav-item mt-3">
                <button
                  v-bind:class="{ active: filter == '6 Month' }"
                  @click="filterData('6 Month')"
                  class="btn nav-link shadow-box"
                >
                  6 Month
                </button>
              </li>
              <li class="nav-item mt-3">
                <button
                  v-bind:class="{ active: filter == 'Year' }"
                  @click="filterData('Year')"
                  class="btn nav-link shadow-box"
                >
                  Year
                </button>
              </li>
              <li class="nav-item mt-3">
                <button
                  v-bind:class="{ active: filter == 'All' }"
                  @click="filterData('All')"
                  class="btn nav-link shadow-box"
                >
                  All
                </button>
              </li>
            </ul>
          </div>

          <div
            class="col-sm-12 col-lg-5"
            v-for="item in filterOrders"
            v-bind:key="item.id"
          >
            <div class="popular-list-items">
              <!--Item -->
              <div class="popular-item shadow-box mt-5">
                <small
                  v-bind:class="changeColorCss(item.orderStatus)"
                  class="
                    et-badge
                    pl-2
                    pr-2
                    text-white
                    position-absolute
                    custom-badge-complete
                  "
                  >{{ item.orderStatus }}</small
                >
                <div class="d-sm-flex align-items-center">
                  <div class="content w-100">
                    <a
                      @click="downloadFile(baseUrl + item.attachment)"
                      href="#"
                      class="
                        position-absolute
                        text-decoration-none
                        wishlist
                        rounded-pill
                        text-white
                        icon-bg
                        bg-black
                      "
                      ><i class="fas fa-download"></i
                    ></a>

                    <div class="meta-row">
                      <div class="float-right">
                        <i class="icon-wallet bit-bold"></i> £{{
                          item.preAmount
                        }}
                        paid
                      </div>
                    </div>
                    <h4 class="font-weight-bold mt-2 mb-3">
                      <span
                        class="
                          text-reset text-capitalize text-decoration-none
                          link-hover
                        "
                        >{{ item.orderName }}</span
                      >
                    </h4>
                    <p class="description">{{ item.services }}</p>
                    <hr />
                    <div class="row">
                      <div class="col-md-6 col-6">
                        <span>
                          <p class="mb-0 text-yellow">Pickup Time</p>
                          <p class="align-bottom mt-0 ml-0 description">
                            {{ item.pickupDate }} , <br />
                            {{ item.pickupTime }}
                          </p>
                        </span>
                      </div>

                      <div class="col-md-6 col-6">
                        <span>
                          <p class="mb-0 text-yellow">Delivery Time</p>
                          <p class="align-bottom mt-0 ml-0 description">
                            {{ item.dropOffDate }} , <br />
                            {{ item.dropOffTime }}
                          </p>
                        </span>
                      </div>
                    </div>

                    <div class="text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-10 text-center">
            <h4><p v-if="filterOrders.length == 0">No Data Available</p></h4>
          </div>

          <div class="col-sm-12 col-lg-10 mt-4">
            <!-- Description -->
            <div class="content-widget">
              <div
                class="content-box shadow-box p-3"
                style="background-color: #ebedef"
              >
                <p class="description mb-0 text-muted">
                  <b>Note: </b>The minimum order is £20. Our facility will check
                  your bags and issue an itemised online receipt. <br />
                  If your order exceeds £20 we’ll be charged after your items
                  are cleaned. You can review our price list to estimate the
                  amount.<br />If you need any help please fell free to contact
                  us by email info@primelaundry.co.uk or contact with customer
                  care via live chat
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Update Modal -->
    <div
      class="modal fade"
      id="updateOrderModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="orderModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title w-100 text-center" id="orderModalLabel">
              Update Order
            </h2>

            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="updateModalClose"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body p-5 container-fluid et-add-restaurant">
            <div class="row justify-content-md-center">
              <div class="col-md-12 overflow-hidden">
                <form
                  autocomplete="on"
                  v-on:submit.prevent="updateOrder"
                  class="col-12 pl-0"
                >
                  <!-- Collection -->
                  <div
                    class="content-group"
                    v-if="selectedOrderStatus == 'Awaiting_Pickup'"
                  >
                    <div class="section-heading text-center">
                      <h2 class="text-capitalize font-weight-bold mb-4">
                        Collection Time
                      </h2>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group mb-4 selectwide">
                          <label class="text-muted text-capitalize"
                            >Collection Date *</label
                          >
                          <br />
                          <Select2
                            id="collectionDay"
                            v-model="collectionDay"
                            :options="collectionDayOption"
                            placeholder="Select Collection Day"
                          />
                          <span class="text-danger" v-if="collectionDay == ''"
                            >*Please select collection day</span
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-4 selectwide">
                          <label class="text-muted text-capitalize"
                            >Collection Time *</label
                          >
                          <br />
                          <Select2
                            id="collectionTime"
                            v-model="collectionTime"
                            :options="collectionTimeOption"
                            placeholder="Select Collection Time"
                          />
                          <span
                            class="text-danger"
                            v-if="collectionDay != '' && collectionTime == ''"
                            >*Please select collection time</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                        <div class="form-group mb-4 selectwide">
                          <label class="text-muted text-capitalize"
                            >Collection Option *</label
                          >
                          <br />
                          <Select2
                            id="collectionOption"
                            v-model="collectionOption"
                            :options="pickupOptions"
                            placeholder="Select collection option"
                          />
                          <span
                            class="text-danger"
                            v-if="collectionOption == ''"
                            >*Please select collection option</span
                          >
                        </div>
                      </div>
                    </div>

                    <br />
                  </div>

                  <!-- Delivery -->
                  <div class="content-group">
                    <div class="section-heading text-center">
                      <h2 class="text-capitalize font-weight-bold mb-4">
                        Delivery Time
                      </h2>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group mb-4 selectwide">
                          <label class="text-muted text-capitalize"
                            >Delivery Date *</label
                          >
                          <br />
                          <Select2
                            id="deliveryDay"
                            v-model="deliveryDay"
                            :options="deliveryDayOptions"
                            placeholder="Select Delivery Day"
                          />
                          <span
                            class="text-danger"
                            v-if="deliveryDay == '' && collectionTime != ''"
                            >*Please select delivery day</span
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group mb-4 selectwide">
                          <label class="text-muted text-capitalize"
                            >Delivery Time *</label
                          >
                          <br />
                          <Select2
                            id="timeofdelivery"
                            v-model="deliveryTime"
                            :options="deliveryTimeOption"
                            placeholder="Select Delivery Time"
                          />
                          <span
                            class="text-danger"
                            v-if="deliveryTime == '' && deliveryDay != ''"
                            >*Please select delivery time</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                        <div class="form-group mb-4 selectwide">
                          <label class="text-muted text-capitalize"
                            >Delivery Option *</label
                          >
                          <br />
                          <Select2
                            id="deliveryOption"
                            v-model="deliveryOption"
                            :options="dropOffOptions"
                            placeholder="Select delivery option"
                          />
                          <span class="text-danger" v-if="deliveryOption == ''"
                            >*Please select delivery option</span
                          >
                        </div>
                      </div>
                    </div>

                    <div>
                      <input
                        type="submit"
                        :disabled="disableButton()"
                        class="
                          btn
                          shadow
                          mt-3
                          bg-yellow
                          text-capitalize text-white
                          action-btn
                        "
                        value="Update"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="checkoutModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="checkoutLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title w-100 text-center" id="checkoutLabel">
              Order Placed Successfully
            </h4>
            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body row" style="height: auto; overflow-y: hidden">
            <div class="col-md-12">
              <div class="hexagon transition">
                <span>
                  <img
                    src="/static/images/thank-you.svg"
                    alt="Thank you- Prime Laundry"
                  />
                </span>
              </div>
              <div class="popular-list-items">
                <!--Item -->
                <div class="popular-item" style="padding-top: 0px">
                  <div class="d-sm-flex align-items-center">
                    <div class="content w-100 pt-3">
                      <h4 class="font-weight-bold mb-3 text-center">
                        <a
                          href="#"
                          class="
                            pt-2
                            text-reset
                            text-center
                            text-capitalize
                            text-decoration-none
                            link-hover
                          "
                          >Order: {{ orderName }}</a
                        >
                      </h4>
                      <p class="description text-center">
                        <span> {{ successServicesName }}</span>
                      </p>
                      <hr />
                      <div class="row justify-content-md-center">
                        <div class="col-md-6 col-6 text-center">
                          <span>
                            <p class="mb-0 text-yellow text-center">
                              Pickup Time
                            </p>
                            <p
                              class="
                                align-bottom
                                mt-0
                                ml-0
                                description
                                text-center
                              "
                            >
                              {{ successCollectionDay }} <br />
                              {{ successCollectionTime }}
                            </p>
                          </span>
                        </div>

                        <div class="col-md-6 col-6 text-center">
                          <span>
                            <p class="mb-0 text-yellow text-center">
                              Delivery Time
                            </p>
                            <p
                              class="
                                align-bottom
                                mt-0
                                ml-0
                                description
                                text-center
                              "
                            >
                              {{ successDeliveryDay }}<br />
                              {{ successDeliveryTime }}
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      href="#"
      id="checkoutModalClick"
      data-dismiss="modal"
      data-toggle="modal"
      data-target="#checkoutModal"
      class="text-yellow d-none"
      >Register OTP</a
    >
  </section>
</template>

<script>
import Vue from "vue";
import TokenExpireLogin from "@/services/TokenExpireLogin";
import CustomerNavigation from "@/core/CustomerNavigation.vue";
import Order from "@/services/Order";
import moment from "moment";
import axios from "axios";
import Select2 from "v-select2-component";

export default {
  name: "Orders",
  data() {
    return {
      showloading: false,

      customerId: 0,
      data1: [], //List of orders
      listOrders: [],
      completedOrders: [],

      selectedOrderStatus: "",
      collectionDay: "",
      collectionDayOption: [],
      collectionTime: "",
      pickupOptions: [
        "Driver collects from you",
        "Driver collects from reception/porter",
      ],
      deliveryDay: "",
      deliveryTime: "",
      deliveryOption: "",
      dropOffOptions: [
        "Driver delivers to you",
        "Driver delivers to reception/porter",
      ],
      collectionOption: "",
      orderId: "",
      filterOrders: [],
      filter: "",

      ecoStatus: true,
      regularStatus: true,

      data2: [], //Collection,delivery time
      collectionTimeArray: [],
      collectionTimeOptionValue: [],
      collectionTimeOption: [],
      collectionTimeOptionEco: [],
      collectionTimeOptionRegular: [],
      maxDrpOffTimeDuration: 0,
      globalDropOffTime: 0,
      deliveryDayOptions: [],
      deliveryTimeOption: [],
      deliveryTimeOptionEco: [],
      deliveryTimeOptionRegular: [],
      minimumDeliveryDate: "",
      baseUrl: this.$api,

      //Order success message
      orderName: "",
      successCollectionDay: "",
      successCollectionTime: "",
      successDeliveryDay: "",
      successDeliveryTime: "",
      successServicesName: "",

      dateTimeSlots: [],

      ecotime: [],
      selectedDate: "",
      dropoffObject: "",
      selectedDeliveryObject: "",
    };
  },
  components: {
    CustomerNavigation,
    Select2,
  },

  mounted() {
    if (
      localStorage.getItem("OrderResponse") != null ||
      localStorage.getItem("OrderResponse") != undefined
    ) {
      this.confirmPayment();
    }
    const userData = this.$store.getters.getUser;

    this.customerId = Number(
      Vue.CryptoJS.AES.decrypt(
        userData["id"],
        Vue.prototype.$secretKey
      ).toString(Vue.CryptoJS.enc.Utf8)
    );

    if (
      this.$route.params.status != undefined &&
      this.$route.params.status == "failed" &&
      localStorage.getItem("sessionId") != null
    ) {
      this.$notify.error({
        title: "Oops",
        message: "Could not process your order.",
      });
    }
    this.getOrderList();
  },
  watch: {
    collectionDay() {
      this.getCollectionTime(this.dateTimeSlots);
    },
    collectionTime() {
      this.getDeliveryDate(this.dateTimeSlots);
    },
    deliveryDay() {
      this.getDeliveryTime(this.dateTimeSlots);
    },
  },
  methods: {
    confirmPayment() {
      const response = JSON.parse(localStorage.getItem("OrderResponse"));
      if (response.isSuccess) {
        var payment_response = response.data1;
        (this.successDeliveryDay = moment(
          payment_response["dropOffDate"]
        ).format("DD MMM YYYY")),
          (this.successDeliveryTime = payment_response["dropOffTime"]);
        this.successCollectionDay = moment(
          payment_response["pickupDate"]
        ).format("DD MMM YYYY");
        this.successCollectionTime = payment_response["pickupTime"];
        this.orderName = payment_response["orderName"];
        this.successServicesName = payment_response["services"].replaceAll(
          "#",
          ","
        );
        this.showloading = false;
        document.getElementById("checkoutModalClick").click();

        this.$store.commit("setTotalOrder");

        this.$notify({
          title: "Succesfull",
          message: "Order Placed",
          type: "success",
        });
      } else {
        this.$notify.error({
          title: "Oops",
          message: "Could not process your order.",
        });
      }

      localStorage.removeItem("OrderResponse");
    },

    async getOrderList() {
      this.showloading = true;

      const response = await Order.getAllOrders(this.customerId);
      // console.log(response);
      if (response) {
        if (response.data.isSuccess) {
          this.data1 = response.data.data1; //Order List-Time slots
          // this.data2 = response.data.data2
          this.setData();
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response != undefined) {
            if (response.data.isSuccess) {
              this.getOrderList();
            }
          } else {
            this.$root.$emit("checkPassword");
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }

      this.showloading = false;
    },

    UpdateOrderModal(id) {
      //Empty existing list
      this.collectionTimeOption = [];
      this.deliveryTimeOption = [];
      this.deliveryDayOptions = [];

      this.orderId = id;
      var updateModel = this.data1.filter((d) => d.id === id); //Data of selected order
      // console.log("modal", updateModel);
      this.selectedOrderStatus = updateModel[0]["orderStatus"];
      this.deliveryOption = updateModel[0]["deliveryOption"];
      this.collectionOption = updateModel[0]["collectionOption"];
      this.collectionDay = moment(updateModel[0]["pickupDate"]).format(
        "DD-MM-YYYY"
      ); //Pickup Date
      // console.log(this.collectionDay);
      // console.log(updateModel[0]["dateTimeSlots"]);
      this.dateTimeSlots = updateModel[0]["dateTimeSlots"]; //show only orderId's timeslots
      // if (this.selectedOrderStatus != "InProgress") {
      const selectedDate = updateModel[0]["dateTimeSlots"].filter(
        (d) => d.date === this.collectionDay
      );
      
      this.collectionTimeOption = selectedDate[0]["time"].split(", ");
      this.collectionTime = updateModel[0]["pickupTime"].trim(); //Pickup time slots

      this.deliveryDay = moment(updateModel[0]["dropOffDate"]).format(
        "DD-MM-YYYY"
      ); //Deliver Date

      this.deliveryTime = updateModel[0]["dropOffTime"].trim();
      this.maxDrpOffTimeDuration = updateModel[0]["maxDrpOffTimeDuration"];
      this.globalDropOffTime = this.maxDrpOffTimeDuration;
      this.deliveryOption = this.dropOffOptions[0];
      // this.getCollectionTime();
      this.getCollectionDate(this.dateTimeSlots);
      // this.getCollectionTime(this.dateTimeSlots);
      // this.getDeliveryDate(this.dateTimeSlots); //Delivery Date List
      // this.getDeliveryTime(this.dateTimeSlots); //Delivery Time List
      // }
    },
    disableButton() {
      if (
        this.collectionDay.length == 0 ||
        this.collectionTime.length == 0 ||
        this.deliveryDay.length == 0 ||
        this.deliveryTime.length == 0 ||
        this.deliveryOption.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    changeColorCss(status) {
      if (status == "Awaiting Pickup") {
        return "bg-success";
      } else if (status == "InProgress") {
        return "bg-info";
      } else if (status == "Completed") {
        return "bg-primary";
      } else if (status == "Awaiting Delivery") {
        return "bg-warning";
      } else if (status == "Cancelled") {
        return "bg-danger";
      }
    },

    filterData(filter) {
      this.filterOrders = [];
      this.filter = filter;
      if (filter == "Month") {
        for (let i = 0; i < this.completedOrders.length; i++) {
          if (
            moment(this.completedOrders[i]["pickupDate"]).format("MM") ==
            moment().format("MM")
          ) {
            this.filterOrders.push(this.completedOrders[i]);
          }
        }
      } else if (filter == "3 Month") {
        for (let i = 0; i < this.completedOrders.length; i++) {
          if (
            moment(this.completedOrders[i]["pickupDate"]).format("MM") >=
            moment().format("MM") - 3
          ) {
            this.filterOrders.push(this.completedOrders[i]);
          }
        }
      } else if (filter == "6 Month") {
        for (let i = 0; i < this.completedOrders.length; i++) {
          if (
            moment(this.completedOrders[i]["pickupDate"]).format("MM") >=
            moment().format("MM") - 6
          ) {
            this.filterOrders.push(this.completedOrders[i]);
          }
        }
      } else if (filter == "Year") {
        for (let i = 0; i < this.completedOrders.length; i++) {
          if (
            moment(this.completedOrders[i]["pickupDate"]).format("YYYY") ==
            moment().format("YYYY")
          ) {
            this.filterOrders.push(this.completedOrders[i]);
          }
        }
      } else {
        this.filterOrders = this.completedOrders;
      }
    },
    getCollectionDate(dateTimeSlot) {
      this.collectionDayOption = [];
      this.dateTimeSlots = dateTimeSlot;
      for (let i = 0; i < this.dateTimeSlots.length; i++) {
        this.collectionDayOption.push(this.dateTimeSlots[i]["date"].trim()); //get date from response
        // this.collectionDay = this.collectionDayOption[0];
      }
      this.getCollectionTime(this.dateTimeSlots);
    },

    getCollectionTime(dateTimeSlot) {
      // this.collectionTime = "";
      this.collectionTimeArray = [];
      this.collectionTimeOption = [];
      this.collectionTimeOptionEco = [];
      this.collectionTimeOptionRegular = [];
      this.collectionTimeOptionValue = [];

      this.dateTimeSlots = dateTimeSlot;
      this.selectedDate = this.dateTimeSlots.filter(
        (d) => d.date === this.collectionDay
      );

      this.collectionTimeOptionValue = this.selectedDate[0]["time"].split(",");
      for (let i = 0; i < this.collectionTimeOptionValue.length; i++) {
        var pickUpArray = [];
        pickUpArray.push(this.collectionTimeOptionValue[i].split("-"));
        var startTime = moment(pickUpArray[0][0], "hh:mm A");
        var endTime = moment(pickUpArray[0][1], "hh:mm A");
        let timeDiff = moment(endTime).diff(moment(startTime), "hours");

        // console.log("diff", timeDiff);
        if (timeDiff == 3) {
          this.collectionTimeOptionEco.push(this.collectionTimeOptionValue[i]);
        } else {
          this.collectionTimeOptionRegular.push(
            this.collectionTimeOptionValue[i]
          );
        }
      }

      let eco = {
        text: "Eco-friendly",
        value: "Eco-friendly",
      };
      let regular = {
        text: "Regular",
        value: "Regular",
      };
      if (this.collectionTimeOptionEco.length != 0) {
        this.collectionTimeOption.push(eco);
      }
      this.collectionTimeOption.push(...this.collectionTimeOptionEco);
      if (this.collectionTimeOptionRegular.length != 0) {
        this.collectionTimeOption.push(regular);
      }
      this.collectionTimeOption.push(...this.collectionTimeOptionRegular);
      if (this.collectionTimeOptionEco.length == 0) {
        this.collectionTime = this.collectionTimeOptionRegular[0];
      } else {
        this.collectionTime = this.collectionTimeOptionEco[0];
      }
      this.collectionTimeArray.push(...this.collectionTimeOptionEco);
      this.collectionTimeArray.push(...this.collectionTimeOptionRegular);
      this.collectionDayOptionValue = "";
      this.collectionTimeDisable = false;
      this.getDeliveryDate(this.dateTimeSlots);
    },

    getDeliveryDate(dateTimeSlot) {
      //status,
      //if collection time/date changed, empty delivery time/date also.
      // if (status == "DateChanged") {
      //   this.deliveryDay = "";
      //   this.deliveryTime = "";
      // }
      this.dateTimeSlots = dateTimeSlot;

      //Get delivery date according to collection date
      var selectedTime = this.collectionTime.split("-");

      var selectedDate = this.collectionDay.split("-");

      selectedDate =
        selectedDate[1] + "/" + selectedDate[0] + "/" + selectedDate[2]; //Format date in mm-dd-yy

      var dates = selectedDate + " " + selectedTime[1]; // mm-dd-yy hh:mm

      var index = this.collectionTimeArray.indexOf(this.collectionTime); //Get index of time selected for drop off duration
      // for (let x = 0; x < this.dateTimeSlots.length; x++) {
      this.dropoffObject = this.dateTimeSlots.filter(
        (d) => d.date === this.collectionDay
      );
      //} //Get that date object from data2
      // var dropoffObject = this.data2.filter(
      //   (d) => d.date === this.collectionDay
      // )
      var dropOffDurationTimeSlot =
        this.dropoffObject[0]["dropOffTime"].split(","); //Get drop off time slots
      if (dropOffDurationTimeSlot.length == 1) {
        index = 0;
      }
      this.maxDrpOffTimeDuration = this.globalDropOffTime;
      if (
        Number(this.maxDrpOffTimeDuration) <
        Number(dropOffDurationTimeSlot[index])
      ) {
        //Compare greater one
        this.maxDrpOffTimeDuration = dropOffDurationTimeSlot[index].trim(); //Replace
      }

      this.minimumDeliveryDate = moment
        .utc(dates)
        .add(this.maxDrpOffTimeDuration, "hours"); //Add drop off duration

      this.deliveryDayOptions = [];

      //Now bind dates for deliver
      // for (let i = 0; i < this.data1.length; i++) {
      for (let j = 0; j < this.dateTimeSlots.length; j++) {
        var listDate = this.dateTimeSlots[j]["date"].split("-");
        var listTime = this.dateTimeSlots[j]["time"].split(",");

        listTime = listTime[listTime.length - 1].split("-");

        listDate =
          listDate[1] +
          "/" +
          listDate[0] +
          "/" +
          listDate[2] +
          " " +
          listTime[1].trim(); // mm-dd-yy hh:mm a
        listDate = moment.utc(listDate);

        //Get list of delivery dates
        if (
          moment.utc(listDate).isAfter(moment.utc(this.minimumDeliveryDate)) ||
          moment.utc(listDate).isSame(moment.utc(this.minimumDeliveryDate))
        ) {
          this.deliveryDayOptions.push(this.dateTimeSlots[j]["date"]);
        }
      }
      // }
      this.deliveryDay = this.deliveryDayOptions[0];
      this.getDeliveryTime(this.dateTimeSlots);
    },

    getDeliveryTime(dateTimeSlot) {
      //status
      //if collection time/date changed, empty delivery time/date also.
      // if (status == "DateChanged") {
      //   this.deliveryTime = "";
      // }
      this.dateTimeSlots = dateTimeSlot;
      this.deliveryTimeOption = [];
      this.deliveryTimeOptionEco = [];
      this.deliveryTimeOptionRegular = [];
      // for (let x = 0; x < this.data1.length; x++) {
      this.selectedDeliveryObject = this.dateTimeSlots.filter(
        (d) => d.date === this.deliveryDay
      );
      // }
      // const selectedDeliveryObject = this.data2.filter(
      //   (d) => d.date === this.deliveryDay
      // )

      var deliveryTimes = this.selectedDeliveryObject[0]["time"].split(", "); //Get selected deliver date time slots
      var selectedDeliveryDate = this.deliveryDay.split("-");
      selectedDeliveryDate =
        selectedDeliveryDate[1] +
        "/" +
        selectedDeliveryDate[0] +
        "/" +
        selectedDeliveryDate[2]; //Format deliver date in mm-dd-yy

      for (let i = 0; i < deliveryTimes.length; i++) {
        var timeArray = deliveryTimes[i].split("- ");
        var startTime = moment(timeArray[0], "hh:mm A");
        var endTime = moment(timeArray[1], "hh:mm A");
        let timeDiff = moment(endTime).diff(moment(startTime), "hours");
        let deliveryTimeArray = [];
        deliveryTimeArray.push(timeArray[0]);
        var dates = selectedDeliveryDate + " " + timeArray[1].trim(); // mm-dd-yy hh:mm

        //Get list of delivery times
        if (
          moment.utc(dates).isAfter(moment.utc(this.minimumDeliveryDate)) ||
          moment.utc(dates).isSame(moment.utc(this.minimumDeliveryDate))
        ) {
          // if (
          //   moment(deliveryTimeArray, "HH:mm a") <
          //   moment("01:00 PM ", "HH:mm a")
          // ) {
          //   //delivery time slots less than 1 PM
          //   continue;
          // } else {
            if (timeDiff == 3) {
              this.deliveryTimeOptionEco.push(deliveryTimes[i]);
            } else {
              this.deliveryTimeOptionRegular.push(deliveryTimes[i]);
            }
          // }
        }
      }
      let eco = {
        text: "Eco-friendly",
        value: "Eco-friendly",
      };
      let regular = {
        text: "Regular",
        value: "Regular",
      };
      if (this.deliveryTimeOptionEco.length != 0) {
        this.deliveryTimeOption.push(eco);
      }
      this.deliveryTimeOption.push(...this.deliveryTimeOptionEco);
      if (this.deliveryTimeOptionRegular.length != 0) {
        this.deliveryTimeOption.push(regular);
      }
      this.deliveryTimeOption.push(...this.deliveryTimeOptionRegular);
      if (this.deliveryTimeOptionEco.length == 0) {
        this.deliveryTime = this.deliveryTimeOptionRegular[0];
      } else {
        this.deliveryTime = this.deliveryTimeOptionEco[0];
      }
    },

    async updateOrder() {
      this.showloading = true;
      var pickupDate = this.collectionDay.split("-");
      pickupDate =
        pickupDate[2] + "-" + pickupDate[1] + "-" + pickupDate[0] + "T00:00:00"; //Format date in yy-mm-dd
      var dropoffDate = this.deliveryDay.split("-");
      dropoffDate =
        dropoffDate[2] +
        "-" +
        dropoffDate[1] +
        "-" +
        dropoffDate[0] +
        "T00:00:00"; //Format date in yy-mm-dd

      const response = await Order.updateOrder({
        id: this.orderId, //orderId
        customerId: this.customerId,
        pickupDate: pickupDate,
        pickupTime: this.collectionTime,
        dropOffDate: dropoffDate,
        dropOffTime: this.deliveryTime,
        deliveryOption: this.deliveryOption,
        collectionOption: this.collectionOption,
      }); //Calling update api

      if (response) {
        if (response.data.isSuccess) {
          this.$notify({
            title: "Successfull",
            message: "Order Updated",
            type: "success",
          });
          this.data1 = response.data.data2;
          // this.dateTimeSlots=response.data.data3;
          // this.data2 = response.data.data3;
          this.getOrderList();
          // this.setData();
          document.getElementById("updateModalClose").click();
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response != undefined) {
            if (response.data.isSuccess) {
              this.updateOrder();
            }
          } else {
            this.$root.$emit("checkPassword");
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }
      this.showloading = false;
    },

    setData() {
      this.listOrders = [];
      this.completedOrders = [];
      for (let i = 0; i < this.data1.length; i++) {
        var amount =
          this.data1[i]["totalAmount"] == 0.0
            ? this.data1[i]["preAmount"]
            : this.data1[i]["totalAmount"];

        if (this.data1[i]["services"] != null) {
          var order = {
            id: this.data1[i]["id"],
            orderName: this.data1[i]["orderName"],
            orderStatus: this.data1[i]["orderStatus"].replace("_", " "),
            dropOffTime: this.data1[i]["dropOffTime"].trim(),
            pickupTime: this.data1[i]["pickupTime"],
            pickupDate: moment(this.data1[i]["pickupDate"]).format(
              "DD MMM YYYY"
            ),
            dropOffDate: moment(this.data1[i]["dropOffDate"]).format(
              "DD MMM YYYY"
            ),
            preAmount: amount,
            services: this.data1[i]["services"].replaceAll("#", ","),
            deliveryOption: this.data1[i]["deliveryOption"],
            collectionOption: this.data1[i]["collectionOption"],
            maxDrpOffTimeDuration: this.data1[i]["maxDrpOffTimeDuration"],
            attachment: this.data1[i]["attachment"],
          };

          if (
            this.data1[i]["orderStatus"] != "Cancelled" &&
            this.data1[i]["orderStatus"] != "Completed"
          ) {
            this.listOrders.push(order);
          } else {
            this.completedOrders.push(order);
          }
        }
      }
      this.filterData("Month");
    },
    downloadFile(link) {
      var format = link.lastIndexOf("."); // Get format pdf, png , etc
      format = link.slice(format, link.length);

      axios({
        method: "get",
        url: link,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, format);
        })
        .catch(() => console.log("error occured"));
    },
    forceFileDownload(response, format) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Invoice." + format); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style scoped>
section.container-fluid {
  padding: 0px 0px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 100px;
}

.el-select {
  display: block !important;
}
</style>

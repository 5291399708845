const WEBSITE_LINK = 'https://primelaundry.co.uk/'

export const services = [
  {
    text: 'Laundry Service',
    href: `${WEBSITE_LINK}/service/laundry-service`,
  },
  {
    text: 'Dry Cleaning',
    href: `${WEBSITE_LINK}/service/dry-cleaning`,
  },
  {
    text: 'Ironing Service',
    href: `${WEBSITE_LINK}/service/ironing`,
  },
  {
    text: 'Alteration & Reparis',
    href: `${WEBSITE_LINK}/service/alteration-repairs`,
  },
  {
    text: 'Shoe Repairs',
    href: `${WEBSITE_LINK}/service/shoe-repair`,
  },
  {
    text: 'Dry Cleaners',
    href: `${WEBSITE_LINK}/service/dry-cleaners`,
  },
  {
    text: 'Laundromat & Laundrette',
    href: `${WEBSITE_LINK}/service/laundromat-laundrette`,
  },
  {
    text: 'Shirt Service',
    href: `${WEBSITE_LINK}/service/shirt-service`,
  },
  {
    text: 'Hotel Guest Laundry',
    href: `${WEBSITE_LINK}/service/hotel-guest-laundry`,
  },
]

export const commercials = [
  {
    text: 'Medical Laundry Services',
    href: `${WEBSITE_LINK}/commercials/medical-laundry-service/`,
  },
  {
    text: 'Airbnb Cleaning Service',
    href: `${WEBSITE_LINK}/commercials/airbnb-cleaning-service/`,
  },
  {
    text: 'Workwear Laundry Services',
    href: `${WEBSITE_LINK}/commercials/workwear-laundry-services/`,
  },
  {
    text: 'Restaurants Laundry Services',
    href: `${WEBSITE_LINK}/commercials/restaurant-laundry-services/`,
  },
  {
    text: 'Hotel Laundry Services',
    href: `${WEBSITE_LINK}/commercials/hotel-laundry-services/`,
  },
  {
    text: 'Cafe Laundry Services',
    href: `${WEBSITE_LINK}/commercials/cafe-laundry-services/`,
  },
  {
    text: 'Spa Laundry Services',
    href: `${WEBSITE_LINK}/commercials/spa-laundry-services/`,
  },
  {
    text: 'Towel Cleaning Service',
    href: `${WEBSITE_LINK}/commercials/towel-cleaning-service/`,
  },
  {
    text: 'Gym Laundry Service',
    href: `${WEBSITE_LINK}/commercials/gym-laundry-service/`,
  },
]

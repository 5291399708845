<template>
  <div>
    <navigation2 v-if="$route.name != 'CheckOut'" />
    <router-view />
    <!-- Start Footer Area -->
    <Footer v-if="$route.name != 'CheckOut'" />
    <!-- End Footer Area; -->
  </div>
</template>

<script>
import navigation2 from "@/core/navigation2.vue";
import Footer from "@/core/footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    navigation2,
    Footer,
  },
  mounted: function() {
    //use your select2 plugin in vue's mounted period
    //$(".Address1").select2();
  },
};
</script>

<template>
  <section class="container-fluid et-add-restaurant position-relative">
    <div class="loaderss" v-if="showloading"></div>
    <div class="row justify-content-md-center">
      <customer-navigation></customer-navigation>
      <div class="col-md-8 overflow-hidden" style="margin-top: 1%">
        <div class="row">
          <div class="col-md-10 section-heading text-center">
            <h2>Refer A Friend</h2>
            <p>Use the voucher code while ordering. You both get discount</p>
          </div>

          <div class="col-lg-10 mt-2 align-items-center">
            <div
              class="
                promo-card promo-card-temp
                pointer
                flip
                w-100
                text-white
                default
              "
              style="margin-left: auto; margin-right: auto"
            >
              <div
                class="
                  promo-header
                  front
                  position-relative
                  align-items-center
                  p-2
                  justify-content-around
                  bg-black
                "
              >
                <div>
                  <h2 class="m-0 font-weight-bold text-white">
                    {{ refercode }}
                  </h2>
                  <input type="hidden" id="testing-code" :value="refercode" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-10 mt-3">
            <div class="text-center">
              <p class="mb-0">Share via:</p>
              <a
                href="#"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#referFriendModal"
                class="btn shadow mt-3 bg-yellow text-capitalize text-white"
                ><i class="fas fa-envelope"></i> Email</a
              >
              <a
                href="#"
                class="
                  btn
                  shadow
                  ml-2
                  mt-3
                  bg-yellow
                  text-capitalize text-white
                "
                @click="copyCode()"
                ><i class="fas fa-copy"></i> Copy Code</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Refer a Friend Mail -->
    <div
      class="modal fade"
      id="referFriendModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="referFriendModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title w-100 text-center" id="referFriendLabel">
              Enter Email
            </h2>
            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              id="ReferEmailClose"
              data-dismiss="modal"
              aria-label="Close"
              @click="clearEmail()"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body p-5">
            <form autocomplete="on" v-on:submit.prevent="sendEmail">
              <div class="form-group">
                <input
                  type="email"
                  autocomplete="on"
                  required
                  class="form-control bg-white shadow-box"
                  v-model="email"
                  placeholder="Provide Email address"
                  v-bind:class="this.reg.test(this.email) ? '' : 'has-error'"
                />
              </div>
              <input
                type="submit"
                class="btn text-white text-capitalize bit-bold bg-yellow"
                value="Submit"
                :disabled="!this.reg.test(this.email)"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TokenExpireLogin from "@/services/TokenExpireLogin";
import CustomerNavigation from "@/core/CustomerNavigation.vue";
import ReferFriend from "@/services/VerifyReferFriendCode";
import Vue from "vue";
export default {
  name: "ReferFriends",
  components: {
    CustomerNavigation,
  },
  data() {
    return {
      refercode: "",
      email: "",
      showloading: false,
      id: 0,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  mounted() {
    const userData = this.$store.getters.getUser;
    this.refercode = userData["referFriendCode"];
    this.id = Number(
      Vue.CryptoJS.AES.decrypt(
        userData["id"],
        Vue.prototype.$secretKey
      ).toString(Vue.CryptoJS.enc.Utf8)
    );
  },
  methods: {
    async sendEmail() {
      this.showloading = true;
      const response = await ReferFriend.postReferFriendMail({
        customerId: this.id,
        email: this.email,
      });
      if (response) {
        if (response.data.isSuccess) {
          this.$notify({
            title: "Successfull",
            message: "Email Sent",
            type: "success",
          });
          document.getElementById("ReferEmailClose").click();
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response != undefined) {
            if (response.data.isSuccess) {
              this.sendEmail();
            }
          } else {
            this.$root.$emit("checkPassword");
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }
      this.email = "";
      this.showloading = false;
    },

    copyCode() {
      let CodeToCopy = document.querySelector("#testing-code");
      CodeToCopy.setAttribute("type", "text");
      CodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        if (msg == "successful") {
          this.$notify({
            title: "Successfull",
            message: "Code Copied",
            type: "success",
          });
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      } catch (err) {
        this.$notify.error({
          title: "Oops",
          message: "Something went wrong",
        });
      }
      CodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    clearEmail() {
      this.email = "";
    },
  },
};
</script>

<style scoped>
section.container-fluid {
  padding: 0px 0px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 100px;
}
</style>

<style>
.has-error {
  border: 1px solid red !important;
}
</style>

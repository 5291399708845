<template>
  <div>
    <section class="container-fluid et-add-restaurant">
      <div class="loaderss" v-if="showloading"></div>
      <div class="row justify-content-md-center">
        <customer-navigation :isLogin="isLogin"></customer-navigation>
        <!-- Content -->
        <div
          class="col-xs-12 col-sm-8 col-lg-6 overflow-hidden"
          style="margin-top: 2%"
        >
          <div
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
            v-if="paymentStatus == 'false'"
          >
            <!-- v-if="paymentStatus == false" -->
            Your checkout session was expired, Place order again!
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <el-steps :active="activeStep" align-center>
            <el-step title="Address"></el-step>
            <el-step title="Services"></el-step>
            <el-step title="Collection"></el-step>
            <el-step title="Payment"></el-step>
          </el-steps>
          <form class="w-100 add-form mt-3" @submit.prevent>
            <!-- Address -->
            <div class="content-group" v-if="addressDiv">
              <div class="section-heading text-center">
                <h2 class="text-capitalize font-weight-bold mb-4">
                  Address Details
                </h2>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group mb-0 margin-bottom">
                    <label class="text-capitalize text1"
                      >Enter Your Full Postcode*</label
                    >
                    <input
                      type="text"
                      autocomplete="on"
                      required
                      class="form-control bg-white shadow-box"
                      id="postCode"
                      name="postCode"
                      v-model="postCode"
                      placeholder="Enter Your Full Postcode"
                    />
                    <label class="text-danger" v-if="!isValid">{{
                      isInValidText
                    }}</label>
                    <label class="text-yellow">{{ isValidText }}</label>
                  </div>
                </div>

                <div class="col-12 col-lg-6 pl-0 pl-15">
                  <button
                    :disabled="!postCode.length"
                    class="
                      bg-yellow
                      btn
                      shadow
                      text-capitalize text-white
                      margin-top
                    "
                    style="margin-top: 8%"
                    @click="IsValidPostcode"
                  >
                    Get Address
                  </button>
                </div>
                <div class="col-md-12" v-if="!showAddressDiv">
                  <div class="form-group mb-4">
                    <label class="text-capitalize text1"
                      >Select your address *</label
                    >
                    <Select2
                      id="Address1"
                      v-model="Address1"
                      :options="addressList"
                      placeholder="Select Address"
                      :disabled="validateForm('addressDropDown')"
                    />
                    <!--:disabled="validateForm('addressDropDown')"-->
                    <span
                      class="text-danger"
                      v-if="
                        (!validateForm('addressDropDown') ||
                          (Address1 == '' && Address1 == undefined)) &&
                          postCode != '' &&
                          (Address1 == '' || Address1 == undefined)
                      "
                      >*Please select address</span
                    >
                  </div>
                </div>
                <div class="col-md-12" v-if="showAddressDiv">
                  <div class="form-group mb-4">
                    <label class="text-capitalize text1">
                      Please enter address details
                      <span
                        ><a
                          href="#"
                          class="text-yellow"
                          @click="
                            ;(showAddressDiv = !showAddressDiv), (Address1 = '')
                          "
                          ><b>*Do you want to select from list?</b></a
                        ></span
                      ></label
                    >
                    <textarea
                      rows="4"
                      cols="50"
                      :disabled="validateForm('postcode')"
                      class="form-control bg-white shadow-box"
                      v-model="Address1"
                      placeholder="Please enter address details"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-4">
                    <label class="text-capitalize text1">
                      Please specify any extra address details</label
                    >
                    <textarea
                      rows="4"
                      cols="50"
                      class="form-control bg-white shadow-box"
                      v-model="Address2"
                      placeholder="Please enter extra address details"
                    ></textarea>
                    <!--:disabled="validateForm('postcode')"-->
                  </div>
                </div>
                <div class="col-md-12 stickyButton">
                  <button
                    class="
                      nextWidth
                      btn
                      shadow
                      bg-yellow
                      text-capitalize text-white
                      action-btn
                    "
                    :disabled="validateForm('address')"
                    v-on:click="showhide('2')"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <!-- Login Page if user is not logged in -->
            <div class="content-group" v-if="isLoginDiv">
              <div class="section-heading text-center">
                <h2 class="text-capitalize font-weight-bold mb-4">
                  Please Log in to continue
                </h2>
              </div>
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <button
                    type="button"
                    class="
                      btn
                      shadow
                      mt-3
                      bg-yellow
                      text-capitalize text-white
                      action-btn
                      mr-2
                    "
                    v-on:click="showhide('3')"
                  >
                    Go Back
                  </button>
                  <a
                    data-toggle="modal"
                    data-target="#loginModal"
                    href="#"
                    class="
                      btn
                      shadow
                      mt-3
                      bg-yellow
                      text-capitalize text-white
                      action-btn
                    "
                    >Log In</a
                  >
                </div>
              </div>
            </div>

            <!-- Services -->
            <div class="content-group" v-if="servicesDiv">
              <div class="section-heading text-center">
                <h2 class="text-capitalize font-weight-bold mb-4">
                  Choose Services
                </h2>
              </div>
              <div>
                <div
                  class="search-result list-view et-cuisines pl-2 pr-2 d-block"
                >
                  <!--col-sm-12-->
                  <div class="popular-list-items justify-content-center row">
                    <!--Item -->
                    <div
                      class="popular-item shadow-box col-sm-6 m-2 pl-2 pr-2"
                      style="border-radius: 10px; max-width: 45%"
                      data-toggle="modal"
                      :data-target="
                        serviceItems.subServiceList != null &&
                        serviceItems.subServiceList.length &&
                        selectedServicesList.indexOf(serviceItems.serviceId) >=
                          0
                          ? '#subServicesModal'
                          : serviceItems.subServiceList.length == 0 &&
                            selectedServicesList.indexOf(
                              serviceItems.serviceId
                            ) >= 0
                          ? '#subServicesModal1'
                          : ''
                      "
                      v-for="serviceItems in data1"
                      v-bind:key="serviceItems.serviceId"
                      v-on:click="
                        addRemoveServices(
                          serviceItems.serviceId,
                          serviceItems.serviceName
                        )
                      "
                    >
                      <div class="align-items-center">
                        <div
                          class="justify-content-center pr-sm-3 row ml-0 mr-0"
                        >
                          <div class="col-sm-6">
                            <img
                              :src="baseUrl + serviceItems.serviceIcon"
                              class="d-block m-auto h-20"
                              :alt="serviceItems.serviceName"
                              style=""
                            />
                          </div>
                          <div
                            class="
                              col-sm-6
                              align-items-center
                              d-lg-none d-sm-none
                              p-0
                            "
                          >
                            <h4
                              class="text-center link-hover mt-3"
                              style="font-size: 15px"
                            >
                              {{ serviceItems.serviceName }}
                            </h4>
                          </div>
                        </div>
                        <div class="content w-100 text-center">
                          <h4
                            class="
                              text-center
                              d-none d-sm-block d-lg-block
                              link-hover
                              mt-2
                            "
                          >
                            {{ serviceItems.serviceName }}
                          </h4>
                          <button
                            v-if="
                              serviceItems.subServiceList != null &&
                                serviceItems.subServiceList.length &&
                                selectedServicesList.indexOf(
                                  serviceItems.serviceId
                                ) >= 0
                            "
                            href="#"
                            data-toggle="modal"
                            data-target="#subServicesModal"
                            class="btn borders justify-content-center mt-3"
                            style="padding: 12px 12px"
                            v-bind:style="[
                              selectedServicesList.indexOf(
                                serviceItems.serviceId
                              ) >= 0
                                ? {
                                    'background-color': '#17B0F3',
                                    color: 'white',
                                  }
                                : {
                                    'background-color': 'white',
                                    color: '#17B0F3',
                                  },
                            ]"
                          >
                            {{
                              selectedServicesList.indexOf(
                                serviceItems.serviceId
                              ) >= 0
                                ? 'x Remove'
                                : '+ Add'
                            }}
                          </button>
                          <button
                            v-else-if="
                              serviceItems.subServiceList.length == 0 &&
                                selectedServicesList.indexOf(
                                  serviceItems.serviceId
                                ) >= 0
                            "
                            style="padding: 12px 12px"
                            v-bind:style="[
                              selectedServicesList.indexOf(
                                serviceItems.serviceId
                              ) >= 0
                                ? {
                                    'background-color': '#17B0F3',
                                    color: 'white',
                                  }
                                : {
                                    'background-color': 'white',
                                    color: '#17B0F3',
                                  },
                            ]"
                            class="btn borders justify-content-center mt-3"
                            data-toggle="modal"
                            data-target="#subServicesModal1"
                          >
                            {{
                              selectedServicesList.indexOf(
                                serviceItems.serviceId
                              ) >= 0
                                ? 'x Remove'
                                : '+ Add'
                            }}
                          </button>
                          <button
                            v-else
                            class="btn borders justify-content-center mt-3"
                            style="padding: 12px 12px"
                            v-bind:style="[
                              selectedServicesList.indexOf(
                                serviceItems.serviceId
                              ) >= 0
                                ? {
                                    'background-color': '#17B0F3',
                                    color: 'white',
                                  }
                                : {
                                    'background-color': 'white',
                                    color: '#17B0F3',
                                  },
                            ]"
                          >
                            {{
                              selectedServicesList.indexOf(
                                serviceItems.serviceId
                              ) >= 0
                                ? 'x Remove'
                                : '+ Add'
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mt-4 pl-0 pr-0">
                      <div class="mb-4">
                        <label class="text-muted text-capitalize"
                          >Any other request?</label
                        >
                        <textarea
                          v-model="specialInstructions"
                          class="form-control bg-white shadow-box"
                          rows="5"
                          placeholder="Add any special cleaning instructions or request"
                        ></textarea>
                      </div>
                    </div>

                    <span
                      class="text-danger"
                      v-if="selectedServicesList.length == 0"
                      >*Please select at least one service</span
                    >
                    <div
                      class="
                        col-md-12
                        pl-0
                        pr-0
                        mt-3
                        stickyButton
                        stickyButton2
                      "
                    >
                      <div class="background">
                        <button
                          type="button"
                          class="
                            buttonWidth
                            btn
                            shadow
                            bg-yellow
                            text-capitalize text-white
                            action-btn
                          "
                          style="float: left"
                          v-on:click="showhide('1')"
                        >
                          <!--stickyButtonPrev  mt-3-->
                          Previous
                        </button>

                        <button
                          type="button"
                          class="
                            buttonWidth
                            btn
                            shadow
                            bg-yellow
                            text-capitalize text-white
                            action-btn
                          "
                          style="float: right"
                          :disabled="validateForm('services')"
                          v-on:click="showhide('3')"
                        >
                          Next</button
                        ><!--stickyButtonNext  mt-3-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Collection -->
            <div class="content-group" v-if="collectionDiv">
              <div class="section-heading text-center">
                <h2 class="text-capitalize font-weight-bold mb-4">
                  Collection Time
                </h2>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Collection Date *</label
                    >
                    <br />
                    <Select2
                      id="collectionDay"
                      v-model="collectionDay"
                      :options="collectionDayOption"
                      placeholder="Select Collection Day"
                      @change="getCollectionTime()"
                    />
                    <span class="text-danger" v-if="collectionDay == ''"
                      >*Please select collection day</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Collection Time *</label
                    >
                    <br />
                    <Select2
                      id="collectionTime"
                      v-model="collectionTime"
                      :options="collectionTimeOption"
                      placeholder="Select Collection Time"
                      @change="getDeliveryDate()"
                    >
                    </Select2>
                    <span
                      class="text-danger"
                      v-if="collectionDay != '' && collectionTime == ''"
                      >*Please select collection time</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Collection Option *</label
                    >
                    <br />
                    <Select2
                      id="collectionOption"
                      v-model="collectionOption"
                      :options="pickupOptions"
                      placeholder="Select collection option"
                    />
                    <span class="text-danger" v-if="collectionOption == ''"
                      >*Please select collection option</span
                    >
                  </div>
                </div>
              </div>
              <br />
            </div>

            <div class="content-group pb-4" v-if="error">
              <span class="text-danger"
                >Please select another collection date, No delivery dates
                available for this date.</span
              >
            </div>

            <!-- Delivery -->
            <div class="content-group" v-if="deliveryDiv">
              <div class="section-heading text-center">
                <h2 class="text-capitalize font-weight-bold mb-4">
                  Delivery Time
                </h2>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Delivery Date *</label
                    >
                    <br />
                    <Select2
                      id="deliveryDay"
                      v-model="deliveryDay"
                      :options="deliveryDayOptions"
                      placeholder="Select Delivery Day"
                      @change="getDeliveryTime()"
                      :disabled="deliveryDayDisable"
                    />
                    <!--:disabled="deliveryDayDisable"-->
                    <span
                      class="text-danger"
                      v-if="deliveryDay == '' && collectionTime != '' && !error"
                      >*Please select delivery day</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Delivery Time *</label
                    >
                    <br />
                    <Select2
                      id="deliveryTime"
                      v-model="deliveryTime"
                      :options="deliveryTimeOption"
                      placeholder="Select Delivery Time"
                      :disabled="deliveryTimeDisable"
                    />

                    <span
                      class="text-danger"
                      v-if="deliveryTime == '' && deliveryDay != ''"
                      >*Please select delivery time</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Delivery Option *</label
                    >
                    <br />
                    <Select2
                      id="deliveryOption"
                      v-model="deliveryOption"
                      :options="dropOffOptions"
                      placeholder="Select delivery option"
                    />
                    <span class="text-danger" v-if="deliveryOption == ''"
                      >*Please select delivery option</span
                    >
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Delivery Instruction?</label
                    >
                    <textarea
                      v-model="deliveryNotes"
                      class="form-control bg-white shadow-box"
                      rows="5"
                      placeholder="Enter delivery instruction"
                    ></textarea>
                  </div>
                  <span class="text-danger" v-if="validateForm('collection')"
                    >*Please fill all details</span
                  >
                </div>
                <div class="col-md-12 stickyButton">
                  <button
                    type="button"
                    class="
                      btn
                      shadow
                      buttonWidth
                      bg-yellow
                      text-capitalize text-white
                      action-btn
                    "
                    style="float: left"
                    v-on:click="showhide('2')"
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    class="
                      btn
                      shadow
                      buttonWidth
                      bg-yellow
                      text-capitalize text-white
                      action-btn
                    "
                    style="float: right"
                    :disabled="validateForm('collection')"
                    v-on:click="showhide('4')"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div class="content-group" v-if="personalDiv">
              <div class="section-heading text-center mb-4">
                <h2 class="text-capitalize font-weight-bold">
                  Personal Information
                </h2>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >First Name *</label
                    >
                    <input
                      type="text"
                      autocomplete="on"
                      required
                      v-model="Firstname"
                      v-bind:class="Firstname != '' ? 'is-valid' : 'is-invalid'"
                      class="form-control bg-white shadow-box"
                      placeholder="Please enter first name"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Last Name *</label
                    >
                    <input
                      type="text"
                      autocomplete="on"
                      required
                      v-model="Lastname"
                      v-bind:class="Lastname != '' ? 'is-valid' : 'is-invalid'"
                      class="form-control bg-white shadow-box"
                      placeholder="Please enter last name"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize">Email Id *</label>
                    <input
                      type="email"
                      autocomplete="on"
                      required
                      v-model="Email"
                      v-bind:class="
                        Email != '' && this.reg.test(this.Email)
                          ? 'is-valid'
                          : 'is-invalid'
                      "
                      class="form-control bg-white shadow-box"
                      placeholder="Please enter email"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize">Postcode *</label>
                    <input
                      type="text"
                      autocomplete="on"
                      disabled="disabled"
                      class="form-control bg-white shadow-box"
                      v-model="postCode"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Address Line1 *</label
                    >
                    <input
                      type="text"
                      autocomplete="on"
                      disabled="disabled"
                      class="form-control bg-white shadow-box"
                      v-model="Address1"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize">
                      Extra Address Details</label
                    >
                    <input
                      type="text"
                      autocomplete="on"
                      v-model="Address2"
                      class="form-control bg-white shadow-box"
                      placeholder="Please enter extra address details"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-sm-12">
                  <div class="mb-4">
                    <label class="text-muted text-capitalize"
                      >Mobile No *</label
                    >
                    <div
                      class="row"
                      style="padding-right: 15px; padding-left: 15px"
                    >
                      <input
                        type="tel"
                        autocomplete="on"
                        disabled="disabled"
                        value="+"
                        class="
                          form-control
                          bg-white
                          shadow-box
                          pl-2
                          pr-2
                          col-1
                          inputLeft
                        "
                        :class="validateIsd(MobileIsd) ? 'valid' : 'invalid'"
                      />
                      <input
                        type="tel"
                        autocomplete="on"
                        v-model="MobileIsd"
                        :maxlength="3"
                        :class="validateIsd(MobileIsd) ? 'valid' : 'invalid'"
                        class="
                          form-control
                          bg-white
                          shadow-box
                          col-3
                          inputRight
                        "
                        placeholder="ISD"
                      />
                      <input
                        type="number"
                        autocomplete="on"
                        required
                        v-model="MobileNo"
                        v-bind:class="
                          (this.MobileNo.length == 11 ||
                            this.MobileNo.length == 10) &&
                          validatePhone(MobileNo)
                            ? 'is-valid'
                            : 'is-invalid'
                        "
                        class="form-control bg-white shadow-box col-sm-8 col-8"
                        placeholder="Please enter mobile"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Voucher Code
                      <span class="text-yellow" v-if="!isLogin"
                        >* Please
                        <a
                          href="#"
                          class="text-yellow text-uppercase"
                          data-toggle="modal"
                          data-target="#loginModal"
                          >log in</a
                        >
                        to apply code</span
                      >
                      <span v-html="voucherText"> </span>
                    </label>
                    <div
                      class="row"
                      style="padding-right: 15px; padding-left: 15px"
                    >
                      <input
                        type="text"
                        autocomplete="on"
                        v-model="ReferFriendCode"
                        :disabled="!isLogin"
                        class="form-control bg-white shadow-box col-9 col-lg-5"
                        placeholder="Please enter voucher code"
                      />
                      <div
                        class="form-group mb-4 col-4 pl-0 ml-0"
                        style="height: auto; width: auto"
                      >
                        <button
                          type="button"
                          class="
                            btn
                            shadow
                            bg-yellow
                            text-capitalize text-white
                            action-btn
                          "
                          :disabled="ReferFriendCode.length > 0 ? false : true"
                          v-on:click="verifyVoucher()"
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <!-- Description -->
                  <div class="content-widget">
                    <div
                      class="content-box shadow-box p-3"
                      style="background-color: #ebedef"
                    >
                      <p class="description mb-0 text-muted text-justify">
                        <b>Note: </b>We will authorise your card with a
                        pre-payment of £20. The final value is calculated after
                        we count / weigh your order. You can calculate the
                        approximate price using our price list. Our minimum
                        order is £20.
                      </p>
                    </div>
                  </div>
                  <span
                    class="text-danger"
                    v-if="
                      this.Firstname == '' ||
                        this.Lastname == '' ||
                        this.Email == ''
                    "
                    >*Please fill all details<br
                  /></span>
                  <span
                    class="text-danger"
                    v-if="
                      !(
                        (this.MobileNo.length == 11 ||
                          this.MobileNo.length == 10) &&
                        this.validatePhone(MobileNo)
                      )
                    "
                    >*Please enter correct mobile number<br
                  /></span>
                </div>
                <div class="col-md-12 stickyButton mt-3">
                  <button
                    type="button"
                    class="
                      buttonWidth
                      btn
                      shadow
                      bg-yellow
                      text-capitalize text-white
                      action-btn
                    "
                    v-on:click="showhide('3')"
                  >
                    Previous
                  </button>
                  <button
                    type="submit"
                    class="
                      buttonWidth
                      btn
                      shadow
                      bg-yellow
                      text-capitalize text-white
                      action-btn
                    "
                    style="float: right"
                    @click="insertOrder()"
                    :disabled="validateForm('personal')"
                  >
                    Check Out
                  </button>
                </div>
              </div>
              <div>
                <p class="pt-4 text-muted text-justify">
                  By continuing you agree to our
                  <a
                    href="https://primelaundry.co.uk/terms-conditions"
                    target="_blank"
                    class="text-yellow"
                    >Terms & Conditions
                  </a>
                  and
                  <a
                    href="https://primelaundry.co.uk/privacy-policy"
                    target="_blank"
                    class="text-yellow"
                    >Privacy Policy</a
                  >. We will authorize your card with a pre payment of £20
                </p>
              </div>
            </div>

            <br />
          </form>
        </div>
        <br />
        <!-- Summary -->
        <div class="col-sm-10 col-lg-3" style="margin-top: 2%">
          <div
            class="shadow-box p-4 bg-white"
            style="position: sticky; top: 4rem; border-radius: 10px"
          >
            <!-- Sidebar Widget -->
            <div class="side-widget mt-4">
              <h5
                class="
                  widget-heading
                  position position-relative
                  mt-3
                  text-capitalize text-center
                  font-weight-bold
                "
              >
                Summary
              </h5>
              <div class="widget-content">
                <div class="order pt-3">
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('address') ? showhide('1') : ''"
                    >Address</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('address') ? showhide('1') : ''"
                  ></i>

                  <p class="description">{{ Address1 }} {{ postCode }}</p>
                </div>

                <div class="order pt-3">
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('services') ? showhide('2') : ''"
                    >Services</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('services') ? showhide('2') : ''"
                  ></i>

                  <p class="description">
                    <span
                      class="
                        tag
                        rounded
                        text-muted text-capitalize
                        mb-2
                        d-inline-block
                        mr-2
                      "
                      v-for="item in selectedServicesName"
                      v-bind:key="item['service_id']"
                    >
                      {{ item['service_name'] }}
                    </span>
                  </p>
                </div>

                <div class="order pt-3">
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('collection') ? showhide('3') : ''"
                    >Collection</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('collection') ? showhide('3') : ''"
                  ></i>

                  <p class="description">
                    {{ collectionDay }} {{ collectionTime }}
                  </p>
                </div>
                <div class="order pt-3">
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('collection') ? showhide('3') : ''"
                    >Delivery</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('collection') ? showhide('3') : ''"
                  ></i>

                  <p class="description">
                    {{ deliveryDay }} {{ deliveryTime }}
                  </p>
                </div>

                <div class="order pt-3">
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('collection') ? showhide('4') : ''"
                    >Payment</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('collection') ? showhide('4') : ''"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </section>
    <div
      class="modal fade"
      id="subServicesModal1"
      tabindex="-1"
      role="dialog"
      aria-labelledby="serviceModalLabel"
      aria-hidden="true"
      v-if="selectedServices.length"
    >
      <!--modal-lg-->
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div
          class="modal-content"
          v-for="serviceItem in selectedService"
          v-bind:key="serviceItem.serviceId"
          style="margin-top: 45px"
        >
          <div class="modal-header justify-content-center p-0">
            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              @click="restoreOldService()"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
            <div class="img">
              <img
                :src="baseUrl + serviceItem.serviceIcon"
                class="d-block m-auto h-20"
                :alt="serviceItem.serviceName"
              />
            </div>
          </div>
          <div class="modal-body px-6 ml-3 mr-3 et-add-restaurant">
            <div class="justify-content-md-center">
              <h2
                class="modal-title w-100 text-center mr-2"
                id="serviceModalLabel"
              >
                {{ serviceItem.serviceName }}
              </h2>
              <p class="text-left mr-2">
                {{ serviceItem.serviceDetail }}
              </p>
              <div class="row text-justify">
                <h3 class="text-left mr-2 col-12">
                  <img
                    src="static/images/ic_item_list.svg"
                    class="mr-2"
                    alt="Item List"
                    style="height: 30px"
                  />
                  Item List:
                </h3>
                <!-- <div> -->
                <label class="icon col-1"><i class="fas fa-circle"></i></label>
                <label class="col-10 p-0"
                  >Prime Laundry doesn’t require you to list specific items.
                  Simply decide which services you need & pack separately.
                </label>
                <!-- </div> -->
                <!-- <div> -->
                <label class="icon col-1"><i class="fas fa-circle"></i></label>
                <label class="col-10 p-0"
                  >We collect your items to our facilities & weigh/count your
                  items according to your requirement.
                </label>
                <!-- </div> -->

                <h3 class="text-left mr-2 col-12">
                  <img
                    src="static/images/ic_works.svg"
                    class="mr-2"
                    alt="Item List"
                    style="height: 30px"
                  />How it works:
                </h3>
                <!-- <div> -->
                <label class="icon col-1"><i class="fas fa-circle"></i></label>
                <label class="col-10 p-0"
                  ><strong>One service per bag:</strong> Prepare your items in
                  different bags (Driver will provide our zipper bags or you can
                  use disposable bags) for your first order. Depending on what
                  service you require.
                </label>
                <!-- </div> -->
                <!-- <div> -->
                <label class="icon col-1"><i class="fas fa-circle"></i></label>
                <label class="col-10 p-0"
                  ><strong>The bags:</strong> Tag them with service name or just
                  tell our driver what service is needed for each bag.</label
                >
                <!-- </div> -->

                <h3 class="text-left mr-2 col-12">
                  <img
                    src="static/images/ic_pay.svg"
                    class="mr-2"
                    alt="Item List"
                    style="height: 30px"
                  />How much do I pay:
                </h3>
                <!-- <div> -->
                <label class="icon col-1"><i class="fas fa-circle"></i></label>
                <label class="col-10 p-0"
                  >The minimum order is £20. Our facility will check your bags
                  and issue an itemised online receipt.
                </label>
                <!-- </div> -->
                <!-- <div> -->
                <label class="icon col-1"><i class="fas fa-circle"></i></label>
                <label class="col-10 p-0"
                  >If your order exceeds £20 we’ll be charged after your items
                  are cleaned. You can review our price list to estimate the
                  amount.
                </label>
                <!-- </div> -->
              </div>
              <button
                data-dismiss="modal"
                class="
                  col-lg-4 col-12
                  btn
                  shadow
                  mt-3
                  bg-yellow
                  text-capitalize text-white
                  action-btn
                "
              >
                Got it
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sub services Modal -->
    <div
      class="modal fade"
      id="subServicesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="serviceModalLabel"
      aria-hidden="true"
      v-if="selectedServices.length"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title w-100 text-left" id="serviceModalLabel">
              Please select your preference for wash
            </h2>

            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              @click="restoreOldService()"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div
            class="modal-body pl-sm-5 pr-sm-5 container-fluid et-add-restaurant"
          >
            <div class="row justify-content-md-center">
              <div class="col-md-12 overflow-hidden">
                <div class="content-group">
                  <div class="row">
                    <div
                      class="
                        search-result
                        list-view
                        et-cuisines
                        col-sm-12
                        d-block
                      "
                    >
                      <div
                        class="popular-list-items justify-content-center row"
                      >
                        <!--Item -->
                        <div
                          class="subpopular-item col-sm-6 subpopular m-2"
                          style="max-width: 47%"
                          v-bind:style="[
                            tempSelectedSubServiceList.indexOf(
                              subserviceItem.subServiceId
                            ) <= -1
                              ? { 'background-color': 'white' }
                              : { 'background-color': '#EDF9FF' },
                          ]"
                          v-for="subserviceItem in selectedServices[0]
                            .subServiceList"
                          v-bind:key="subserviceItem.subServiceId"
                          v-on:click="
                            addRemoveSubServices(subserviceItem.subServiceId)
                          "
                        >
                          <div class="align-items-center">
                            <div class="text-center row ml-0 mr-0">
                              <div class="col-12 d-none d-lg-block">
                                <img
                                  :src="baseUrl + subserviceItem.serviceIcon"
                                  class="d-block m-auto"
                                  :alt="subserviceItem.serviceName"
                                  style="width: 50%"
                                />
                              </div>
                              <div class="col-12 d-lg-none">
                                <img
                                  :src="baseUrl + subserviceItem.serviceIcon"
                                  class="d-block m-auto h-20"
                                  :alt="subserviceItem.serviceName"
                                  style="width: 4rem; object-fit: contain"
                                />
                              </div>
                              <div
                                class="
                                  col-sm-12 col-lg-6
                                  pl-0
                                  d-flex
                                  align-items-center
                                "
                              >
                                <h4
                                  class="d-lg-none text-justify link-hover mb-0"
                                >
                                  {{ subserviceItem.serviceName }}
                                </h4>
                              </div>
                            </div>
                            <div class="content w-100">
                              <h4 class="d-none d-lg-block link-hover">
                                {{ subserviceItem.serviceName }}
                              </h4>
                              <p
                                class="
                                  description
                                  mt-3
                                  mb-sm-4
                                  mt-md-0
                                  text-sm
                                  mb-0
                                "
                                style=""
                              >
                                {{ subserviceItem.description }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                </div>

                <div class="row">
                  <h5 class="text-left col-12">Select temparature for wash*</h5>
                  <div class="col-lg-4 col-4">
                    <input
                      id="rdb30"
                      type="radio"
                      name="temparature"
                      value="30"
                      v-model="temparature"
                      checked="checked"
                    />&nbsp;<label>30<span>&#176;</span></label>
                  </div>
                  <div class="col-lg-4 col-4">
                    <input
                      type="radio"
                      name="temparature"
                      value="45"
                      v-model="temparature"
                    />&nbsp;<label>45<span>&#176;</span></label>
                  </div>
                  <div class="col-lg-4 col-4">
                    <input
                      type="radio"
                      name="temparature"
                      value="60"
                      v-model="temparature"
                    />&nbsp;<label>60<span>&#176;</span></label>
                  </div>

                  <h5 class="text-left col-12">
                    Select temparature for tumble dry*
                  </h5>
                  <div class="col-lg-4 col-sm-4 col-5">
                    <input
                      type="radio"
                      name="tumbletemparature"
                      value="Low"
                      v-model="tumbletemparature"
                      checked
                    />&nbsp;<label>Low</label>
                  </div>

                  <div class="col-lg-4 col-sm-4 col-5">
                    <input
                      type="radio"
                      name="tumbletemparature"
                      value="Medium"
                      v-model="tumbletemparature"
                    />&nbsp;<label>Medium</label>
                  </div>

                  <div class="col-lg-4 col-sm-4">
                    <input
                      type="radio"
                      name="tumbletemparature"
                      value="Cool Air Dry"
                      v-model="tumbletemparature"
                    />&nbsp;<label>Cool Air Dry</label>
                  </div>
                </div>
                <button
                  data-dismiss="modal"
                  @click="SaveSubService()"
                  class="
                    col-lg-2 col-12
                    btn
                    shadow
                    mt-3
                    bg-yellow
                    text-capitalize text-white
                    action-btn
                  "
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Instructions for 1st time :- right now this is commented  -->
    <div
      class="modal fade"
      id="instructionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="instructionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2
              class="modal-title w-100 text-center"
              id="instructionModalLabel"
            >
              About Our Service
            </h2>

            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="">
              <div class="et-sidebar">
                <!-- Sidebar Widget -->
                <div class="side-widget">
                  <div class="widget-content">
                    <h5
                      class="
                        widget-heading
                        position position-relative
                        mt-3
                        mb-4
                        text-capitalize text-center
                        font-weight-bold
                      "
                    >
                      Item List
                    </h5>
                    <div class="meta mb-3 text-left d-flex pr-1 pl-1">
                      <i
                        class="
                          rounded-pill
                          d-inline-block
                          mr-2
                          icon-bg
                          bg-white
                        "
                      >
                        <i class="fas fa-check f-icon"></i>
                      </i>
                      <p class="align-bottom description d-inline-block m-0">
                        No need to list your items we will weigh or Count the
                        items for you once we collected your bag.
                      </p>
                    </div>

                    <div class="meta mb-3 text-left d-flex pr-1 pl-1">
                      <i
                        class="
                          rounded-pill
                          d-inline-block
                          mr-2
                          icon-bg
                          bg-white
                        "
                      >
                        <i class="fas fa-check f-icon"></i>
                      </i>
                      <p class="align-bottom description d-inline-block m-0">
                        We will prepare your invoice after we receive your items
                        & your final payment will be processed after delivery.
                      </p>
                    </div>

                    <h5
                      class="
                        widget-heading
                        position position-relative
                        mt-3
                        mb-4
                        text-capitalize text-center
                        font-weight-bold
                      "
                    >
                      Prices
                    </h5>
                    <div class="meta mb-3 text-left d-flex pr-1 pl-1">
                      <i
                        class="
                          rounded-pill
                          d-inline-block
                          mr-2
                          icon-bg
                          bg-white
                        "
                      >
                        <i class="fas fa-check f-icon"></i>
                      </i>
                      <p class="align-bottom description d-inline-block m-0">
                        You can check our price list to estimate the amount.
                      </p>
                    </div>

                    <div class="meta mb-3 text-left d-flex pr-1 pl-1">
                      <i
                        class="
                          rounded-pill
                          d-inline-block
                          mr-2
                          icon-bg
                          bg-white
                        "
                      >
                        <i class="fas fa-check f-icon"></i>
                      </i>
                      <p class="align-bottom description d-inline-block m-0">
                        £20 minimum order
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      href="#"
      id="checkoutClick"
      data-dismiss="modal"
      data-toggle="modal"
      data-target="#checkoutModal"
      class="text-yellow"
      style="display: none"
    ></a>
    <!-- <a
      href="#"
      id="instructionsClick"
      data-dismiss="modal"
      data-toggle="modal"
      data-target="#instructionModal"
      class="text-yellow"
      style="display: none"
    ></a> -->
  </div>
</template>

<script>
import Vue from 'vue'
import TokenExpireLogin from '@/services/TokenExpireLogin'
import CustomerNavigation from '@/core/CustomerNavigation.vue'
import OrderNow from '@/services/OrderNow'
import VerifyReferFriendCode from '@/services/VerifyReferFriendCode'
import AddressList from '@/services/AddressList'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import checkout from '@/services/checkout'
import store from '@/store/index'
// import Select from "vue-select2";
import Select2 from 'v-select2-component'
// import { saveAs } from 'file-saver';

const TIMEZONE = 'Europe/London'

export default {
  name: 'BookNow',
  data() {
    return {
      showloading: false,
      totalOrder: 0,
      isOfferCode: false,
      isReferCode: false,

      postCode: '',
      addressList: [],
      allAddressList: [],
      isValid: true,
      isValidText: '',
      isInValidText: '',
      showAddressDiv: false,
      validPostCodes: [],
      checkFlag: false,

      addressDiv: true,
      isLoginDiv: false,

      data1: [], //Services
      selectedService: [],
      selectedServices: [],
      servicesDiv: false,
      selectedServicesList: [],
      selectedServicesName: [],
      selectedServicesNameArray: [],
      selectedSubServiceName: [],
      selectedSubServicesList: [],
      selectedServiceSubServiceList: [],
      tempSelectedServiceSubServiceList: [],
      tempSelectedSubServiceList: [],
      specialInstructions: '',
      dropOffTimeDuration: 0,
      tempSubServiceId: 0,

      data2: [], //Collection/Delivery Date Time
      collectionTimeArray: [],
      collectionTimeOptionValue: [],
      collectionDiv: false,
      deliveryDiv: false,
      collectionDay: '',
      collectionDayOption: [],
      collectionTime: '',
      collectionTimeOption: [],
      collectionTimeOptionEco: [],
      collectionTimeOptionRegular: [],
      deliveryTimeOptionEco: [],
      deliveryTimeOptionRegular: [],
      collectionOption: '',
      pickupOptions: [
        'Driver collects from you',
        'Driver collects from reception/porter',
      ],
      collectionTimeDisable: true,
      deliveryDay: '',
      deliveryTime: '',
      deliveryTimeOption: [],
      deliveryDayOptions: [],
      deliveryOption: '',
      dropOffOptions: [
        'Driver delivers to you',
        'Driver delivers to reception/porter',
      ],
      deliveryDayDisable: false,
      deliveryTimeDisable: false,
      minimumDeliveryDate: '',
      deliveryNotes: '',

      personalDiv: false,
      Firstname: '',
      Lastname: '',
      Email: '',
      MobileIsd: '44',
      MobileNo: '',
      Address1: '',
      Address2: '',
      ReferFriendCode: '',
      voucherValid: true,
      voucherText: '',
      activeStep: 1,
      userId: 0,
      orderName: '',

      paymentStatus: true,

      paymentDiv: false,
      cardName: '',
      complete: false,
      // termsheck:false,

      temparature: 30,
      tumbletemparature: 'Low',

      error: false,
      errorMessage: '',

      ecoStatus: true,
      regularStatus: true,
      loginPopup: true,
      serviceList: [],
      serviceId: '',
      isLogin: false,
      baseUrl: this.$api,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    }
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      title:
        'Book Your Laundry, Dry Cleaning and Ironing in London With Prime Laundry',
      meta: [
        {
          name: 'description',
          content:
            'Want to book your laundry, dry cleaning and ironing in London? Connect with Prime Laundry and get your clean clothes and wear it. Free Collection & Delivery!',
        },
        {
          property: 'og:title',
          content:
            'Book Your Laundry, Dry Cleaning and Ironing in London With Prime Laundry',
        },
        { property: 'og:site_name', content: 'Prime Laundry' },
        {
          property: 'og:description',
          content:
            'Want to book your laundry, dry cleaning and ironing in London? Connect with Prime Laundry and get your clean clothes and wear it. Free Collection & Delivery!',
        },
        { property: 'og:url', content: 'https://app.primelaundry.co.uk' },
      ],
    }
  },
  components: {
    CustomerNavigation,
    Select2,
    //Card
  },

  mounted() {
    //Postcode data entered in website and redirected here
    if (this.$route.query.postcode != undefined) {
      var postCode = this.$route.query.postcode
      // store new postcode
      this.$store.commit('setPostcode', postCode.toUpperCase())
    } 
    
    if (this.Address1 == '') {
      this.Address1 = this.$store.getters.getAddress1
    }

    this.$root.$on('setCheckout', () => {
      let checkoutStaus = localStorage.getItem('setcheckout')
      if (checkoutStaus == 'true' || checkoutStaus == true) {
        this.showhide('4')
        document.getElementById('close').click() //login popup close
      }
    })

    this.paymentStatus = localStorage.getItem('paymentStatus') // to check time expired or not
    localStorage.removeItem('paymentStatus')
    // localStorage.removeItem("paymentStatus");
    this.collectionOption = this.pickupOptions[0]
    this.deliveryOption = this.dropOffOptions[0]

    //User came from website refer friend click

    // if (localStorage.getItem("refer-friend")) {
    //   document.getElementById("LoginClick").click();
    // }
    this.postCode = this.$store.getters.getPostcode
    if (this.postCode != '') {
      this.IsValidPostCodeAPICall()
      this.IsValidPostcode()
    }

    //Fill data again after login refresh
    // if (
    //   localStorage.getItem('Postcode') != null &&
    //   localStorage.getItem('Postcode') != '' &&
    //   localStorage.getItem('Postcode') != 'null'
    // ) {
    //   this.postCode = localStorage.getItem('Postcode')
    // }

    if (this.$route.hash == '#website') {
      document.getElementById('LoginClick').click()
    }

    this.isLogin = localStorage.getItem('isLogin')

    if (this.isLogin == true || this.isLogin == 'true') {
      this.isLoginDiv = false
      const userData = this.$store.getters.getUser
      this.Firstname = userData['firstname']
      this.Lastname = userData['lastname']
      this.Address2 = userData['address2']
      this.Email = Vue.CryptoJS.AES.decrypt(
        userData['email'],
        Vue.prototype.$secretKey
      ).toString(Vue.CryptoJS.enc.Utf8)
      if (
        userData['mobileNo'] != '' &&
        userData['mobileNo'] != null &&
        userData['mobileNo'] != 'null'
      ) {
        this.MobileIsd = userData['mobileNo']
          .split('-')[0]
          .replace('+', '')
          .trim()
        this.MobileNo = userData['mobileNo'].split('-')[1] //get phone number
      } else {
        this.MobileIsd = '44'
        this.MobileNo = ''
      }

      this.userId = Number(
        Vue.CryptoJS.AES.decrypt(
          userData['id'],
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8)
      )
      this.totalOrder = Number(userData['totalOrder'])
    }
    this.$root.$on('changeLogin', () => {
      this.isLogin = localStorage.getItem('isLogin')
      if (this.isLoginDiv) {
        this.loginPopup = false
        this.showhide('4') //2
      }

      // if (this.Address1 == undefined ) {
      // this.Address1 = "";
      // }

      const userData = this.$store.getters.getUser
      if (this.Firstname == '') {
        this.Firstname = userData['firstname']
        this.Lastname = userData['lastname']
      }

      if (this.Address2 == '') {
        this.Address2 = userData['address2']
      }
      if (this.Email == '') {
        this.Email = Vue.CryptoJS.AES.decrypt(
          userData['email'],
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8)
      }
      if (this.MobileIsd == '') {
        this.MobileIsd = userData['mobileIsd']
      }
      if (this.MobileNo == '') {
        if (userData['mobileNo'] == null || userData['mobileNo'] == 'null') {
          this.MobileNo = ''
        } else {
          this.MobileNo = userData['mobileNo'].split('-')[1]
        }
      }

      this.userId = Number(
        Vue.CryptoJS.AES.decrypt(
          userData['id'],
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8)
      )
      this.totalOrder = Number(userData['totalOrder'])
    })

    if (this.postCode == null) {
      this.postCode = ''
    }
    this.Address2 = this.$store.getters.getAddress2
  },
  watch: {
    ReferFriendCode() {
      this.voucherText = ''
      ;(this.isReferCode = false), (this.offerCode = false)

      if (this.ReferFriendCode.length) {
        this.voucherText =
          "<span class='text-muted'> <b> *Voucher not applied </b> </span>"
      }
    },
    postCode() {
      if (this.postCode == '') {
        this.Address1 = ''
      }
      //Fill Data again after login refresh
      if (
        localStorage.getItem('Refresh') == true ||
        localStorage.getItem('Refresh') == 'true'
      ) {
        localStorage.setItem('Refresh', false)

        // if (
        //   localStorage.getItem('Postcode') != null &&
        //   localStorage.getItem('Postcode') != '' &&
        //   localStorage.getItem('Postcode') != 'null'
        // ) {
        //   this.postCode = localStorage.getItem('Postcode')
        //   if (this.postCode != null && this.postCode != '') {
        //     this.IsValidPostcode()
        //   }
        // }
        // this.postCode = this.$store.getters.getPostcode

        if (
          localStorage.getItem('Address2') != null &&
          localStorage.getItem('Address2') != '' &&
          localStorage.getItem('Address2') != 'null'
        ) {
          this.Address2 = localStorage.getItem('Address2')
        }

        if (
          localStorage.getItem('Address1') != null &&
          localStorage.getItem('Address1') != '' &&
          localStorage.getItem('Address1') != 'null'
        ) {
          this.Address1 = localStorage.getItem('Address1')
          this.Address1 = this.$store.getters.getAddress1
        }

        localStorage.removeItem('Postcode')
        localStorage.removeItem('Address1')
        localStorage.removeItem('Address2')
        localStorage.removeItem('Refresh')
      } else {
        // this.Address1=""
        this.showAddressDiv = false
        this.addressList = []
        this.isValidText = ''
        this.isValid = true
        if (
          this.postCode != null &&
          this.postCode.length > 0 &&
          this.isLogin != true &&
          this.isLogin != 'true'
        ) {
          // this.$store.commit('setPostcode', this.postCode.toUpperCase())
        }
      }
    },
    Address1() {
      // this.Address1=localStorage.getItem("Address1");
      // console.log("address",this.Address1)
      if (this.Address1 == 'Not in the list?') {
        this.showAddressDiv = true
        this.Address1 = ''
      }
      if (
        this.Address1 != null &&
        this.Address1.length > 0 &&
        this.isLogin != true &&
        this.isLogin != 'true'
      ) {
        localStorage.setItem('Address1', this.Address1)
      }
    },
    Address2() {
      if (
        this.Address2 != null &&
        this.Address2.length > 0 &&
        this.isLogin != true &&
        this.isLogin != 'true'
      ) {
        localStorage.setItem('Address2', this.Address2)
      }
    },
  },
  methods: {
    // saveStaticDataToFile() {
    //         var blob = new File(["Welcome to Websparrow.org."],
    //             { type: "text/plain;charset=utf-8" });
    //         saveAs(blob, "../logs/"+ this.date + ".txt");
    // },
    validateIsd(Isd) {
      let regex = /^[0-9]{2}$/
      let regex1 = /^[0-9]{3}$/
      let regex2 = /^[0-9]{1}$/

      if (Isd.match(regex) || Isd.match(regex1) || Isd.match(regex2)) {
        return true
      } else {
        return false
      }
    },

    validatePhone(phone) {
      let regex = /^[0-9]{10}$/
      let regex1 = /^[0-9]{11}$/

      if (phone.match(regex) || phone.match(regex1)) {
        return true
      } else {
        return false
      }
    },

    async IsValidPostcode() {
      this.showAddressDiv = false

      this.showloading = true

      //Check if matches with list of postcodes available
      if (this.validPostCodes.length > 0) {
        for (let x = 0; x < this.validPostCodes.length; x++) {
          if (this.postCode.toUpperCase().startsWith(this.validPostCodes[x])) {
            this.checkFlag = true
            this.getAddressList(this.postCode.toUpperCase())
            break
          }
        }
        if (!this.checkFlag) {
          this.IsValidPostCodeAPICall()
        }
      } else {
        this.IsValidPostCodeAPICall()
      }
    },

    async getAddressList(postCode) {
      const addressResponse = await AddressList.getAllAddress(postCode)

      this.allAddressList = []

      if (addressResponse != undefined) {
        this.isValid = true
        this.isInValidText = ''

        this.isValidText = 'We serve in your area'

        this.allAddressList = addressResponse.data.addresses

        for (let i = 0; i < this.allAddressList.length; i++) {
          var address = this.allAddressList[i].split(',')
          address = address.filter((val) => val !== ' ') //Remove empty spaces

          this.addressList.push(address.join())
        }
        this.addressList.push('Not in the list?')

        let addressObj = this.addressList.filter((e) => e == this.Address1)

        if (addressObj.length == 0) {
          this.Address1 = ''
        }
      } else {
        this.isValid = false
        //this.isInValidText ="Sorry, we don't offer our services in your area at the moment!"
        this.isValidText = ''

        this.IsValidPostCodeAPICall()
      }

      this.showloading = false
    },

    async IsValidPostCodeAPICall() {
      this.showloading = true

      const response = await OrderNow.IsValidPostcode(
        this.postCode.toUpperCase()
      )

      if (response) {
        //If postocode valid
        if (
          response.data.isSuccess &&
          response.data.statusCode != 5 &&
          response.data.statusCode != 4
        ) {
          localStorage.setItem(
            'Time',
            this.$CryptoJS.AES.encrypt(
              new Date().toString(),
              Vue.prototype.$secretKey
            ).toString()
          )
          this.$store.commit('setPostcode', this.postCode.toUpperCase())
          this.getAddressList(this.postCode)
          this.data1 = response.data.data1

          //Collection Delivery Time Date
          this.data2 = response.data.data2
        }
        //Valid but incomplete postcode
        else if (response.data.statusCode == 4) {
          this.isInValidText = response.data.message
          this.isValid = false

          //List of services
          this.data1 = response.data.data1

          //Collection Delivery Time Date
          this.data2 = response.data.data2
          for (let x = 0; x < this.data2.length; x++) {
            this.collectionDayOption.push(this.data2[x]['date'])
          }

          //List of Valid Postcodes
          this.validPostCodes = response.data.data3
        }
        //We dont serve in your area
        else if (response.data.statusCode == 5) {
          this.isInValidText = response.data.message
          this.isValid = false
        } else {
          this.isValid = false
          this.isInValidText =
            "Sorry, we don't offer our services in your area at the moment!"
          this.isValidText = ''
        }
      } else {
        if (localStorage.getItem('TokenExpire')) {
          const response = await TokenExpireLogin.tokenExpireLogin()
          if (response != undefined) {
            if (response.data.isSuccess) {
              this.IsValidPostCodeAPICall()
            }
          } else {
            this.$root.$emit('checkPassword')
          }
        } else {
          this.$notify.error({
            title: 'Oops',
            message: 'Something went wrong',
          })
        }
      }

      this.showloading = false
    },

    getCollectionDate() {
      //Get maximum drop off duration from services
      // console.log(this.selectedServicesList)
      for (let i = 0; i < this.selectedServicesList.length; i++) {
        var tempObj = this.data1.filter(
          (d) => d.serviceId === this.selectedServicesList[i]
        )

        // this.dropOffTimeDuration=0;
        if (this.selectedServicesList.length == 1) {
          this.dropOffTimeDuration = tempObj[0]['dropOffTimeDuration'].trim()
        }
        if (this.dropOffTimeDuration <= tempObj[0]['dropOffTimeDuration']) {
          this.dropOffTimeDuration = tempObj[0]['dropOffTimeDuration'].trim()
        }
      }

      var days = this.dropOffTimeDuration / 24
      this.collectionDayOption = []

      for (let x = 0; x <= this.data2.length - days; x++) {
        let maxCurrentDateLastStartTime = null
        const momentDate = momentTZ(this.data2[x]['date'], 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        )

        for (const time of this.data2[x]['time'].split(',')) {
          let [startTime] = time.split(' -')

          startTime = startTime.trim()

          if (!maxCurrentDateLastStartTime)
            maxCurrentDateLastStartTime = startTime

          const isGreater = momentTZ(
            `${momentDate} ${startTime}`
          ).isSameOrAfter(
            `${momentDate} ${maxCurrentDateLastStartTime}`,
            'seconds'
          )

          if (isGreater) maxCurrentDateLastStartTime = startTime
        }

        const now = momentTZ()
          .tz(TIMEZONE)
          .format('YYYY-MM-DD hh:mm A')

        const isDateBeforeNowTime = momentTZ(
          `${momentDate} ${maxCurrentDateLastStartTime}`
        ).isSameOrBefore(now)

        if (isDateBeforeNowTime) continue

        this.collectionDayOption.push(this.data2[x]['date'])
      }

      this.collectionDay = this.collectionDayOption[0]
      this.getCollectionTime()
    },

    getCollectionTime() {
      // this.deliveryDay = "";
      // this.deliveryTime = "";
      // this.collectionTime = "";
      this.collectionTimeArray = []
      this.collectionTimeOption = []
      this.collectionTimeOptionEco = []
      this.collectionTimeOptionRegular = []
      const selectedDate = this.data2.filter(
        (d) => d.date === this.collectionDay
      )
      const now = momentTZ().tz(TIMEZONE)

      this.collectionTimeOptionValue = selectedDate[0]['time'].split(',')
      for (let i = 0; i < this.collectionTimeOptionValue.length; i++) {
        var pickUpArray = []
        pickUpArray.push(this.collectionTimeOptionValue[i].split('-'))
        var startTime = moment(pickUpArray[0][0], 'hh:mm A') // start time
        var endTime = moment(pickUpArray[0][1], 'hh:mm A') // End time

        const dateTime = moment(
          `${selectedDate[0].date} ${pickUpArray[0][0]}`,
          'DD-MM-YYYY hh:mm A'
        ).format('YYYY-MM-DD hh:mm A')

        const isDateTimeSameOrBeforeNowTime = moment(dateTime).isSameOrBefore(
          now.format('YYYY-MM-DD hh:mm A'),
          'seconds'
        )

        // current date time is before now time
        if (isDateTimeSameOrBeforeNowTime) continue

        let timeDiff = moment(endTime).diff(moment(startTime), 'hours') // get the diff between two
        // console.log("diff", timeDiff);
        if (timeDiff == 3) {
          this.collectionTimeOptionEco.push(this.collectionTimeOptionValue[i])
        } else {
          this.collectionTimeOptionRegular.push(
            this.collectionTimeOptionValue[i]
          )
        }
      }
      let eco = {
        text: 'Eco-friendly',
        value: 'Eco-friendly',
      }
      let regular = {
        text: 'Regular',
        value: 'Regular',
      }
      if (this.collectionTimeOptionEco.length != 0) {
        this.collectionTimeOption.push(eco)
      }
      this.collectionTimeOption.push(...this.collectionTimeOptionEco)
      if (this.collectionTimeOptionRegular.length != 0) {
        this.collectionTimeOption.push(regular)
      }
      this.collectionTimeOption.push(...this.collectionTimeOptionRegular)
      if (this.collectionTimeOptionEco.length == 0) {
        this.collectionTime = this.collectionTimeOptionRegular[0]
      } else {
        this.collectionTime = this.collectionTimeOptionEco[0]
      }
      this.collectionTimeArray.push(...this.collectionTimeOptionEco)
      this.collectionTimeArray.push(...this.collectionTimeOptionRegular)
      this.collectionDayOptionValue = ''
      this.collectionTimeDisable = false
      this.getDeliveryDate()
    },

    getDeliveryDate() {
      this.deliveryDayDisable = false
      // this.deliveryDay = "";
      // this.deliveryTime = "";
      //Get delivery date according to collection date

      //Get maximum drop off duration from serives
      for (let i = 0; i < this.selectedServicesList.length; i++) {
        var tempObj = this.data1.filter(
          (d) => d.serviceId === this.selectedServicesList[i]
        )
        // if (this.selectedServicesList.length == 1) {
        //   this.dropOffTimeDuration = tempObj[0]["dropOffTimeDuration"].trim();
        // }
        // this.dropOffTimeDuration = 0;
        if (this.dropOffTimeDuration < tempObj[0]['dropOffTimeDuration']) {
          this.dropOffTimeDuration = tempObj[0]['dropOffTimeDuration']
        }
      }

      var index = this.collectionTimeArray.indexOf(this.collectionTime) //Get index of time selected for drop off duration

      var dropoffObject = this.data2.filter(
        (d) => d.date === this.collectionDay
      ) //Get that date object from data2
      var dropOffDurationTimeSlot = dropoffObject[0]['dropOffTime'].split(',') //Get drop off time slots
      if (dropOffDurationTimeSlot.length == 1) {
        index = 0
      }
      if (
        Number(this.dropOffTimeDuration) <
        Number(dropOffDurationTimeSlot[index]) //.trim()//[index] dropoffDurationTimeSlot[index]:- is not getting the correct dropoff time
      ) {
        //Compare greater one
        this.dropOffTimeDuration = dropOffDurationTimeSlot[index] //Replace .trim()
      }

      var selectedTime = this.collectionTime.split('-')

      var selectedDate = this.collectionDay.split('-')

      selectedDate =
        selectedDate[1] + '/' + selectedDate[0] + '/' + selectedDate[2] //Format date in mm-dd-yy

      var dates = selectedDate + ' ' + selectedTime[1] // mm-dd-yy hh:mm .trim()

      this.minimumDeliveryDate = moment
        .utc(dates)
        .add(this.dropOffTimeDuration, 'hours') //Add drop off duration

      this.deliveryDayOptions = []

      //Now bind dates for deliver
      for (let i = 0; i < this.data2.length; i++) {
        var listDate = this.data2[i]['date'].split('-')
        var listTime = this.data2[i]['time'].split(',')

        listTime = listTime[listTime.length - 1].split('-')

        listDate =
          listDate[1] +
          '/' +
          listDate[0] +
          '/' +
          listDate[2] +
          ' ' +
          listTime[1].trim() // mm-dd-yy hh:mm a
        listDate = moment.utc(listDate)

        //Get list of delivery dates
        if (
          moment.utc(listDate).isAfter(moment.utc(this.minimumDeliveryDate)) ||
          moment.utc(listDate).isSame(moment.utc(this.minimumDeliveryDate))
        ) {
          this.deliveryDayOptions.push(this.data2[i]['date'])
        }
      }
      this.deliveryDay = this.deliveryDayOptions[0]
      this.getDeliveryTime()
      // this.deliveryDayDisable = false;
    },

    getDeliveryTime() {
      this.error = false
      this.deliveryTimeOption = []
      this.deliveryTimeOptionEco = []
      this.deliveryTimeOptionRegular = []
      this.deliveryTime = ''
      const selectedDeliveryObject = this.data2.filter(
        (d) => d.date === this.deliveryDay
      )
      if (selectedDeliveryObject.length != 0) {
        var deliveryTimes = selectedDeliveryObject[0]['time'].split(', ')

        var selectedDeliveryDate = this.deliveryDay.split('-')

        selectedDeliveryDate =
          selectedDeliveryDate[1] +
          '/' +
          selectedDeliveryDate[0] +
          '/' +
          selectedDeliveryDate[2] //Format date in mm-dd-yy

        for (let i = 0; i < deliveryTimes.length; i++) {
          var timeArray = deliveryTimes[i].split('-')
          var startTime = moment(timeArray[0], 'hh:mm A')
          var endTime = moment(timeArray[1], 'hh:mm A')
          let timeDiff = moment(endTime).diff(moment(startTime), 'hours')
          let deliveryTimeArray = []
          deliveryTimeArray.push(timeArray[0])

          var dates = selectedDeliveryDate + ' ' + timeArray[1] // mm-dd-yy hh:mm .trim()
          //Get list of delivery times
          if (
            moment.utc(dates).isAfter(moment.utc(this.minimumDeliveryDate)) ||
            moment.utc(dates).isSame(moment.utc(this.minimumDeliveryDate))
          ) {
            // if (
            //   moment(deliveryTimeArray, "HH:mm a") <
            //   moment("01:00 PM ", "HH:mm a") // this only shows the time afer 1 PM
            // ) {
            //   //delivery time slots less than 1 PM
            //   continue;
            // } else {
            if (timeDiff == 3) {
              //Show only that time slots that difference is 3
              this.deliveryTimeOptionEco.push(deliveryTimes[i])
            } else {
              // Show only that time slots that difference is 3
              this.deliveryTimeOptionRegular.push(deliveryTimes[i])
            }
            // }
          }
        }

        let eco = {
          text: 'Eco-friendly',
          value: 'Eco-friendly',
        }
        let regular = {
          text: 'Regular',
          value: 'Regular',
        }
        if (this.deliveryTimeOptionEco.length) {
          this.deliveryTimeOption.push(eco)
        }
        this.deliveryTimeOption.push(...this.deliveryTimeOptionEco)
        if (this.deliveryTimeOptionRegular.length) {
          this.deliveryTimeOption.push(regular)
        }
        this.deliveryTimeOption.push(...this.deliveryTimeOptionRegular)
        if (this.deliveryTimeOptionEco.length == 0) {
          this.deliveryTime = this.deliveryTimeOptionRegular[0]
        } else {
          this.deliveryTime = this.deliveryTimeOptionEco[0]
        }
        this.deliveryTimeDisable = false
      } else {
        this.deliveryDay = ''
        this.deliveryTime = ''
        this.deliveryTimeDisable = true
        this.deliveryDayDisable = true
        this.error = true
      }
    },

    validateForm(form) {
      if (form == 'postcode') {
        //Enable address field if postocode entered and valid
        if (this.postCode.length >= 6) {
          return !this.isValid
        } else {
          return true
        }
      } else if (form == 'addressDropDown') {
        if (this.isValid && this.addressList.length) {
          return false
        } else {
          return true
        }
      } else if (form == 'address') {
        //Enable Next button if post code and address available

        if (this.postCode != '' && this.Address1 != '' && this.isValid) {
          return false
        } else {
          return true
        }
      } else if (form == 'services') {
        if (
          this.selectedServicesList != null &&
          this.selectedServicesList.length > 0 &&
          this.Address1 != ''
        ) {
          return false
        } else {
          return true
        }
      } else if (form == 'collection') {
        if (
          this.collectionDay.length != 0 &&
          this.collectionTime.length != 0 &&
          this.collectionOption.length != 0 &&
          this.deliveryDay.length != 0 &&
          this.deliveryTime.length != 0 &&
          this.deliveryOption.length != 0 &&
          this.selectedServicesList.length != 0 &&
          this.Address1 != ''
        ) {
          return false
        } else {
          return true
        }
      } else if (form == 'personal') {
        if (
          this.Firstname != '' &&
          this.Lastname != '' &&
          this.Email != '' &&
          this.validatePhone(this.MobileNo) &&
          this.Address1 != '' &&
          this.validateIsd(this.MobileIsd) &&
          this.selectedServicesList.length != 0 &&
          (this.MobileNo.length == 11 || this.MobileNo.length == 10)
        ) {
          if (this.ReferFriendCode.length > 0 && this.voucherValid) {
            return false
          } else if (this.ReferFriendCode.length > 0 && !this.voucherValid) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }
    },

    showhide(id) {
      // eslint-disable-next-line no-extra-semi
      this.addressDiv = false
      this.servicesDiv = false
      this.collectionDiv = false
      this.deliveryDiv = false
      this.personalDiv = false
      this.paymentDiv = false
      this.isLoginDiv = false

      if (id == '1') {
        this.addressDiv = true
        this.activeStep = 1
        this.isLoginDiv = false
      } else if (id == '2') {
        this.error = false
        //2
        // if (this.isLogin != true && this.isLogin != "true") {
        //   this.isLoginDiv = true;
        //   console.log("hello")
        //   document.getElementById("LoginClick").click();
        //   console.log("login pop up closed")

        //   //document.getElementById('LoginClick').click();
        // } else {
        if (!this.validateForm('address')) {
          this.$store.commit('setAddress1', this.Address1)
          this.$store.commit('setAddress2', this.Address2)
          this.isLoginDiv = false
          this.servicesDiv = true
          this.activeStep = 2
        } else {
          this.addressDiv = true
          this.activeStep = 1
          this.isLoginDiv = false
        }
        // }
        // if (
        //   this.totalOrder == 0 &&
        //   (this.isLogin == true || this.isLogin == "true")
        // ) {
        //   document.getElementById("instructionsClick").click();
        // }
      } else if (id == '3') {
        this.collectionDiv = true
        this.deliveryDiv = true
        this.activeStep = 3
        this.getCollectionDate()
      } else if (id == '4') {
        if (this.isLogin != true && this.isLogin != 'true') {
          this.isLoginDiv = true
          document.getElementById('LoginClick').click()
          localStorage.setItem('setcheckout', true)
        } else {
          this.personalDiv = true
          this.activeStep = 4
        }
      } else if (id == '5') {
        this.paymentDiv = true
      }
      window.scrollTo(0, 0)
    },

    addRemoveServices(serviceId, name) {
      const temp = this.data1

      //Remove servicename if already exists
      for (let x = 0; x < this.selectedServicesName.length; x++) {
        if (this.selectedServicesName[x]['service_id'] == serviceId) {
          this.selectedServicesName.splice(x, 1)
        }
      }

      if (this.selectedServicesList.indexOf(serviceId) >= 0) {
        this.selectedServicesList.splice(
          this.selectedServicesList.indexOf(serviceId),
          1
        )
        this.ReferFriendCode = '' // When remove any services or add only services then refer friend code should be null.
        // console.log("remove", this.selectedServicesList);
        //Remove from temporary selected serviceSubService List also
        for (
          let i = 0;
          i < this.tempSelectedServiceSubServiceList.length;
          i++
        ) {
          if (
            this.tempSelectedServiceSubServiceList[i]['service_id'] == serviceId
          ) {
            var old_subservice = this.tempSelectedServiceSubServiceList[i][
              'subservice_id'
            ]

            this.tempSelectedSubServiceList.splice(
              this.tempSelectedSubServiceList.indexOf(old_subservice),
              1
            )

            this.tempSelectedServiceSubServiceList.splice(i, 1)
          }
        }

        //Remove from selectedServiceSubServiceList also

        for (let i = 0; i < this.selectedServiceSubServiceList.length; i++) {
          if (
            this.selectedServiceSubServiceList[i]['service_id'] == serviceId
          ) {
            old_subservice = this.selectedServiceSubServiceList[i][
              'subservice_id'
            ]

            this.selectedSubServicesList.splice(
              this.selectedSubServicesList.indexOf(old_subservice),
              1
            )

            this.selectedServiceSubServiceList.splice(i, 1)
          }
        }
      }
      //Add service to list
      else {
        this.selectedServicesList.push(serviceId)

        this.selectedServices = temp.filter((d) => d.serviceId === serviceId)
        this.selectedService = []
        this.selectedService.push(this.selectedServices[0])

        if (this.selectedServices[0].subServiceList.length) {
          var subservice_item = {
            service_id: serviceId,
            subservice_id: this.selectedServices[0].subServiceList[0][
              'subServiceId'
            ],
            subservice_name: this.selectedServices[0].subServiceList[0][
              'serviceName'
            ].split(' '),
          }

          this.tempSelectedServiceSubServiceList.push(subservice_item)

          this.selectedServiceSubServiceList.push(subservice_item)

          this.selectedSubServicesList.push(
            this.selectedServices[0].subServiceList[0]['subServiceId']
          )
          this.tempSelectedSubServiceList.push(
            this.selectedServices[0].subServiceList[0]['subServiceId']
          )

          var service_item = {
            service_id: serviceId,
            service_name: name + '-' + subservice_item.subservice_name[0],
          }

          this.selectedServicesName.push(service_item)

          // console.log("1st", this.selectedServicesName);
        } else {
          var service_items = {
            service_id: serviceId,
            service_name: name,
          }
          this.selectedServicesName.push(service_items)
          // console.log("2nd", this.selectedServicesName);
        }
      }
    },

    addRemoveSubServices(subServiceId) {
      //Remove/Add from temporary subservice List
      for (let i = 0; i < this.tempSelectedServiceSubServiceList.length; i++) {
        if (
          this.tempSelectedServiceSubServiceList[i]['service_id'] ==
          this.selectedServices[0]['serviceId']
        ) {
          var old_subservice = this.tempSelectedServiceSubServiceList[i][
            'subservice_id'
          ]
          this.tempSelectedSubServiceList.splice(
            this.tempSelectedSubServiceList.indexOf(old_subservice),
            1
          )

          var subServiceObject = this.selectedServices[0][
            'subServiceList'
          ].filter((d) => d.subServiceId === subServiceId)

          this.tempSelectedServiceSubServiceList[i][
            'subservice_id'
          ] = subServiceId
          this.tempSelectedServiceSubServiceList[i][
            'subservice_name'
          ] = subServiceObject[0]['serviceName'].split(' ')

          this.tempSelectedSubServiceList.push(subServiceId)
        }
      }
    },

    SaveSubService() {
      this.selectedSubServicesList = this.tempSelectedSubServiceList

      this.selectedServiceSubServiceList = this.tempSelectedServiceSubServiceList

      for (let x = 0; x < this.selectedServicesName.length; x++) {
        if (
          this.selectedServicesName[x]['service_id'] ==
          this.tempSelectedServiceSubServiceList[0]['service_id']
        ) {
          var name = this.selectedServicesName[x]['service_name'].split('-')

          name =
            name[0] +
            '-' +
            this.tempSelectedServiceSubServiceList[0]['subservice_name'][0]

          this.selectedServicesName[x]['service_name'] = name
        }
      }

      this.tempSelectedSubServiceList = []
      this.tempSelectedServiceSubServiceList = []
    },
    restoreOldService() {
      this.tempSelectedServiceSubServiceList = this.selectedSubServicesList
      this.tempSelectedServiceSubServiceList = this.selectedServiceSubServiceList
    },

    async verifyVoucher() {
      this.showloading = true
      const userData = this.$store.getters.getUser
      const response = await VerifyReferFriendCode.postVerifyReferFriendCode({
        customerId: Number(
          Vue.CryptoJS.AES.decrypt(
            userData['id'],
            Vue.prototype.$secretKey
          ).toString(Vue.CryptoJS.enc.Utf8)
        ),
        referCode: this.ReferFriendCode,
        plateForm: 1,
        serviceid: this.selectedServicesList,
      })
      // console.log(response);

      if (response) {
        if (response.data.isSuccess) {
          this.voucherValid = true
          this.isReferCode = response.data.data1['isReferCode']
          this.isOfferCode = response.data.data1['isOfferCode']
          this.voucherText =
            "<span class='text-yellow'> <b> *Voucher Valid </b> </span>"
        } else if (response.data.statusCode == 2) {
          this.voucherValid = false
          this.isReferCode = false
          this.isOfferCode = false
          this.voucherText =
            "<span class='text-danger'> <b> *" + response.data.message
          ;('</b> </span>')
        } else {
          this.voucherValid = false
          this.isReferCode = false
          this.isOfferCode = false
          this.voucherText =
            "<span class='text-danger'> <b> *" +
            response.data.message +
            '</b> </span>'
        }
      } else {
        if (localStorage.getItem('TokenExpire')) {
          const response = await TokenExpireLogin.tokenExpireLogin()
          if (response != undefined) {
            if (response.data.isSuccess) {
              this.verifyVoucher()
            }
          } else {
            this.$root.$emit('checkPassword')
          }
        } else {
          this.$notify.error({
            title: 'Oops',
            message: 'Something went wrong',
          })
        }
      }

      this.showloading = false
    },

    async insertOrder() {
      const timeFirst = localStorage.getItem('Time') //Get the time of local storage
      const x = new Date(
        Vue.CryptoJS.AES.decrypt(timeFirst, Vue.prototype.$secretKey).toString(
          Vue.CryptoJS.enc.Utf8
        )
      )
      const timeSecond = new Date()
      var diff = (timeSecond.getTime() - x.getTime()) / 1000
      diff /= 60
      var time = Math.abs(Math.round(diff)) //Get difference between current time and localstorage's
      if (time >= 20) {
        localStorage.setItem('Refresh', true)
        localStorage.setItem('paymentStatus', false)
        this.$router.go()
      } else {
        this.showloading = true

        var isLogin = localStorage.getItem('isLogin')

        if (isLogin == null || isLogin == 'false') {
          if (!this.$session.exists()) {
            this.$session.start()
          }

          var userObjectSession = {
            firstname: this.Firstname,
            lastname: this.Lastname,
            email: this.Email,
            contactIsd: this.MobileIsd,
            contactNo: this.MobileNo,
            postcode: this.postCode,
            address: this.Address1,
            Address2: this.Address2,
          }

          this.$session.set('userObject', userObjectSession)
          document.getElementById('LoginClick').click()
        } else {
          //if user selects wash, dry and fold then temparature should be 30 and tumble temperature should ne Low otherwise both the fields are blank.
          let servicesName = this.selectedServicesName.filter(function(x) {
            if (
              x.service_name == 'Wash , Dry and Fold-Mixed' ||
              x.service_name == 'Wash , Dry and Fold-Separate'
            ) {
              return true
            }
          })
          if (servicesName.length == 0) {
            this.temparature = 0
            this.tumbletemparature = ''
          }

          const userData = this.$store.getters.getUser

          this.userId = Number(
            Vue.CryptoJS.AES.decrypt(
              userData['id'],
              Vue.prototype.$secretKey
            ).toString(Vue.CryptoJS.enc.Utf8)
          )

          var pickupDate = this.collectionDay.split('-')

          pickupDate =
            pickupDate[2] +
            '-' +
            pickupDate[1] +
            '-' +
            pickupDate[0] +
            'T00:00:00' //Format date in yy-mm-dd

          var dropoffDate = this.deliveryDay.split('-')
          dropoffDate =
            dropoffDate[2] +
            '-' +
            dropoffDate[1] +
            '-' +
            dropoffDate[0] +
            'T00:00:00' //Format date in yy-mm-dd
          var offerCode = ''
          var referCode = ''
          if (this.isOfferCode) {
            offerCode = this.ReferFriendCode
          } else if (this.isReferCode) {
            referCode = this.ReferFriendCode
          }
          if (this.mobileIsd == '') {
            this.mobileIsd = '44'
          }
          const insertResponse = await OrderNow.InsertOrder({
            specialInstruction: this.specialInstructions,
            customerId: this.userId,
            preAmount: 20.0,
            pickupDate: pickupDate,
            pickupTime: this.collectionTime,
            dropOffDate: dropoffDate,
            dropOffTime: this.deliveryTime,
            deliveryNotes: this.deliveryNotes,
            Services: this.selectedServicesList.join(),
            SubServices: this.selectedSubServicesList.join(),
            collectionOption: this.collectionOption,
            deliveryOption: this.deliveryOption,

            orderAddress: {
              firstname: this.Firstname,
              lastname: this.Lastname,
              email: this.Email,
              contactNo: '+' + this.MobileIsd + '-' + +this.MobileNo,
              postCode: this.postCode,
              addressLine1: this.Address1,
              addressLine2: this.Address2,
              plateform: 'Web',
            },
            useReferCode: referCode,
            offerCode: offerCode,
            washtemperature: Number(this.temparature),
            tumbletemperature: this.tumbletemparature,
          })

          // console.log(insertResponse);
          if (insertResponse) {
            if (insertResponse.data.isSuccess) {
              localStorage.setItem(
                'OrderId',
                this.$CryptoJS.AES.encrypt(
                  insertResponse.data.data1['orderId'].toString(),
                  Vue.prototype.$secretKey
                ).toString()
              )
              // this.$session=insertResponse.data.data1["sessionId"];
              localStorage.setItem(
                'sessionId',
                this.$CryptoJS.AES.encrypt(
                  insertResponse.data.data1['sessionId'],
                  Vue.prototype.$secretKey
                ).toString()
              )
              const user = {
                firstname: this.Firstname,
                lastname: this.Lastname,
                mobileNo: '+' + this.MobileIsd + '-' + this.MobileNo,
                address2: this.Address2,
                email: this.$CryptoJS.AES.encrypt(
                  this.Email,
                  Vue.prototype.$secretKey
                ).toString(),
                id: this.$CryptoJS.AES.encrypt(
                  this.userId.toString(),
                  Vue.prototype.$secretKey
                ).toString(),
                totalOrder: this.totalOrder,
              }
              store.commit('setUser', user)
              localStorage.removeItem('setcheckout')
              await checkout.checkout(insertResponse.data.data1['sessionId'])
            } else {
              this.$notify.error({
                title: 'Oops',
                message: 'Something went wrong',
              })
            }
          } else {
            if (localStorage.getItem('TokenExpire')) {
              const response = await TokenExpireLogin.tokenExpireLogin()
              if (response != undefined) {
                if (response.data.isSuccess) {
                  this.insertOrder()
                }
              } else {
                this.$root.$emit('checkPassword')
              }
            } else {
              this.$notify.error({
                title: 'Oops',
                message: 'Something went wrong',
              })
            }
          }
        }

        this.showloading = false
      }
    },
  },
}
</script>

<style scoped>
section.container-fluid {
  padding: 0px 0px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 100px;
}
::v-deep .el-step__icon {
  height: 32px;
  width: 32px;
}
/* ::v-deep .el-step.is-horizontal{
  flex-basis:22%;
} */

::v-deep .el-step__head.is-process {
  color: #c0c4cc !important;
  border-color: #c0c4cc !important;
}

::v-deep .el-step__title.is-process {
  font-weight: 100 !important;
  color: #c0c4cc !important;
}

::v-deep .el-step__title.is-finish {
  color: #09285b !important;
}

.tag {
  border: 2px dashed #ddd;
  padding: 5px 10px;
  transition: all ease 0.3s;
}

.grid {
  display: grid;
}

.gap-2 {
  gap: 0.5rem;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.h-20 {
  height: 100px;
}
.buttonWidth {
  width: 160px;
}
.icon {
  color: black !important;
  width: 20px;
  height: 20px;
  font-size: 10px !important;
  margin-left: 6px;
  margin-right: 10px;
}

@media (min-width: 377px) {
  .h-20 {
    height: 70px;
  }
  /* .buttonWidth{
    width:160px;
  } */
}

@media (min-width: 576px) {
  .h-20 {
    height: 90px;
  }
}

@media (max-width: 377px) {
  .h-20 {
    height: 50px;
  }
}

@media (max-width: 377px) {
  .text-sm {
    font-size: 14px;
    line-height: 1.25rem;
  }
  .nextWidth {
    width: 100%;
  }

  /* .btn:disabled {
    opacity: unset;
  } */
}
@media (max-width: 576px) {
  .stickyButton {
    background-color: #fefefe;
    padding: 8px;
    position: fixed;
    z-index: 11;
    bottom: 0;
  }
  .stickyButton2 {
    margin-left: -8px;
  }
  .background {
    padding-left: 10px;
    padding-right: 10px;
  }
  #backTop {
    bottom: 60px;
  }
  /* .buttonWidth {
    width: 180px;
  } */

  /* .btn:disabled {
    opacity: unset;
  } */
  .nextWidth {
    width: 100%;
  }
}
</style>

<style>
@media (max-width: 1024px) {
  .pl-15 {
    padding-left: 15px !important;
    text-align: center;
    margin-bottom: 15px;
  }
  .margin-top {
    margin-top: 0px !important;
  }

  .margin-bottom {
    margin-bottom: 0px;
  }
}
</style>

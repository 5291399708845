<template>
  <div>
    <div class="loaderss" v-if="showloading"></div>
    <!-- Topbar -->
    <nav class="et-navbar">
      <div class="container">
        <!-- <div class="container"> -->
        <div class="row pb-2 pt-2">
          <div class="logo">
            <a :href="websiteLink"
              ><img
                src="static/images/logo.svg"
                alt="Prime Laundry- Logo"
                style="width: 140px"
            /></a>
          </div>
          <div class="nav-links">
            <ul
              class="d-none nostyle inline d-md-inline-block mt-2 pt-2 p-0 mb-0"
            >
              <!--SUBMENU-->
              <li class="ml-lg-4 pl-2 mr-2 dropdown">
                <a :href="websiteLink + '/service'">Services</a>

                <ul
                  class="submenu transition bg-white rounded nostyle p-4 shadow"
                >
                  <li v-for="link in serviceLinks" :key="link.href">
                    <a :href="link.href">{{ link.text }}</a>
                  </li>
                </ul>
              </li>
              <li class="ml-lg-4 pl-2 mr-2">
                <a :href="websiteLink + '/pricing/'">Pricing</a>
              </li>

              <li class="ml-lg-4 pl-2 mr-2 dropdown">
                <a :href="websiteLink + '/commercial-laundry-service/'"
                  >Commercial</a
                >
                <ul
                  class="submenu transition bg-white rounded nostyle p-4 shadow"
                >
                  <li v-for="link in commercialLinks" :key="link.href">
                    <a :href="link.href">{{ link.text }}</a>
                  </li>
                </ul>
              </li>

              <li class="ml-lg-4 pl-2 mr-2">
                <a :href="websiteLink + '/blog/'">Blog</a>
              </li>

              <li class="ml-lg-4 pl-2 mr-2">
                <a :href="websiteLink + '/about-us/'">About Us</a>
              </li>
            </ul>
          </div>
          <div class="text-md-right ml-auto d-flex">
            <div class="pt-2 mt-2 p-0 mb-0">
              <span v-if="isLogin" style="color: #585c62; font-size: 14px"
                >Welcome {{ displayName }}</span
              >
              <a
                v-bind:class="!isLogin ? '' : 'd-none'"
                href="#"
                class="
                  login-popup-model
                  white-space-nowrap
                  login
                  bg-yellow
                  shadow
                  text-white
                "
                style="color: #18191f"
                id="LoginClick"
                data-toggle="modal"
                data-target="#loginModal"
                >Log in</a
              >
              <!-- <i class="fas fa-sign-in-alt"></i> -->
              <span id="borderLeft" class="ml-2">
                <a
                  v-if="isLogin"
                  href="#"
                  class="text-black white-space-nowrap ml-2"
                  @click="logOut()"
                  >Logout<i class="fas fa-sign-out-alt ml-2"></i></a
              ></span>
            </div>
            <div class="ml-lg-4 responsiveButton">
              <router-link to="/"
                ><a
                  v-bind:class="isLogin ? '' : 'd-none'"
                  class="
                    bookNow
                    btn
                    shadow
                    bg-yellow
                    text-capitalize text-white
                  "
                  v-if="$route.name != 'BookNow'"
                  @click="CallGTag"
                  >Book Now</a
                ></router-link
              >
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </nav>
    <!-- Mobile menu start -->
    <nav id="et-mobile-nav">
      <ul>
        <!--SUBMENU-->
        <li class="dropdown">
          <a href="#">Services</a>

          <ul>
            <li v-for="link in serviceLinks" :key="link.href">
              <a :href="link.href">{{ link.text }}</a>
            </li>
          </ul>
        </li>
        <li>
          <a :href="websiteLink + '/pricing'">Pricing</a>
        </li>
        <li>
          <a :href="`${websiteLink}/commercial-laundry-service`">Commercial</a>
          <ul>
            <li v-for="link in commercialLinks" :key="link.href">
              <a :href="link.href">{{ link.text }}</a>
            </li>
          </ul>
        </li>

        <li>
          <a :href="websiteLink + '/blog/'">Blog</a>
        </li>

        <li>
          <a :href="websiteLink + '/about-us/'">About Us</a>
        </li>
        <li v-if="isLogin">
          <a href="/Account">Account</a>
        </li>
        <li v-if="isLogin">
          <a href="/Orders">Orders</a>
        </li>
        <li v-if="isLogin">
          <a href="/refer-friends">Refer Friends</a>
        </li>
        <li v-if="isLogin">
          <a href="ChangePassword">Change password</a>
        </li>
        <li>
          <span v-if="isLogin" style="color: #585c62; font-size: 14px"
            >Welcome {{ displayName }}</span
          >
        </li>
        <li>
          <a
            v-bind:class="!isLogin ? '' : 'd-none'"
            href="#"
            class="login-popup-model text-black white-space-nowrap"
            data-toggle="modal"
            data-target="#loginModal"
            >Log in</a
          >
          <a
            v-if="isLogin"
            href="#"
            class="text-black white-space-nowrap"
            @click="logOut()"
            >Logout<i class="fas fa-sign-out-alt ml-2"></i
          ></a>
        </li>
      </ul>
    </nav>
    <!-- Mobile menu end -->
    <!-- Login Modal -->
    <div
      class="modal"
      id="loginModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="loginModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title w-100" id="loginModalLabel">Sign In</h2>

            <button
              type="button"
              class="close position-absolute rounded-pill"
              data-dismiss="modal"
              id="close"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body p-5">
            <!-- <p class="text-center">Sign In To Continue</p> -->
            <div class="mb-4 row">
              <div class="my-2 col-lg-4 col-12">
                <GoogleLogin
                  :params="params"
                  :onSuccess="onSuccess"
                  :onFailure="onFailure"
                  class="sso-btn google-bg"
                >
                  <span class="mr-2"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      aria-hidden="true"
                    >
                      <title>Google</title>
                      <g fill="none" fill-rule="evenodd">
                        <path
                          fill="#4285F4"
                          d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"
                        ></path>
                        <path
                          fill="#EA4335"
                          d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"
                        ></path>
                      </g>
                    </svg>
                  </span>
                  Sign in with Google
                </GoogleLogin>
              </div>
              <div class="my-2 col-lg-4 col-12">
                <button
                  type="button"
                  class="sso-btn facebook-bg"
                  @click.prevent="loginWithFacebook"
                >
                  <span class="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
                      />
                    </svg>
                  </span>
                  Sign in with Facebook
                </button>
              </div>
              <div class="my-2 col-lg-4 col-12">
                <!-- <VueAppleLogin> -->
                <button
                  type="button"
                  class="sso-btn apple-bg"
                  @click="loginWithApple"
                >
                  <span class="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="apple"
                      class="svg-inline--fa fa-apple fa-w-12"
                      role="img"
                      width="16"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                      />
                    </svg>
                  </span>
                  Sign in with Apple
                </button>
              </div>
            </div>
            <!-- <div class="continue__content">
              <span class="continue__text">
                Or Continue With Email Address</span
              >
            </div> -->
            <form v-on:submit.prevent="login" class="row">
              <div class="form-group col-12">
                <input
                  type="email"
                  required
                  class="form-control bg-white borderTextbox"
                  placeholder="Email"
                  v-model="username"
                  autocomplete="on"
                  v-bind:class="
                    this.reg.test(this.username) ? 'is-valid' : 'is-invalid'
                  "
                />
              </div>
              <div class="form-group col-12">
                <div class="input-group">
                  <input
                    :type="passwordFieldType"
                    required
                    autocomplete="new-password"
                    class="form-control bg-white"
                    placeholder="Password"
                    v-model="password"
                  />

                  <div class="input-group-prepend">
                    <span @click="switchVisibility()" class="input-group-text"
                      ><i
                        :class="
                          passwordFieldType == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>
              </div>
              <div class="form-group col-12">
                <p>
                  Forgot password?
                  <a
                    href="#"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#resetModal"
                    class="text-yellow"
                    >Reset now</a
                  >
                </p>
              </div>

              <div class="form-group col-lg-6 .ml-auto col-12">
                <p>
                  <input
                    type="submit"
                    class="
                      btn
                      text-white text-capitalize
                      bit-bold
                      bg-yellow
                      btn-hover
                    "
                    value="SIGN IN"
                  />
                </p>
              </div>
              <div class="form-group col-lg-6 .ml-auto col-12">
                <p class="para">
                  Don't have an account?
                  <a
                    href="#"
                    id="RegisterClick"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#signupModal"
                    class="text-yellow"
                    @click="openRegister()"
                    >Sign up</a
                  >
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Password Reset Modal -->
    <div
      class="modal fade"
      id="resetModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="resetModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <h2 class="modal-title w-100" id="resetModalLabel">
                Reset Password
              </h2>
              <p class="mt-2">We will send you otp to this email id</p>
            </div>
            <button
              type="button"
              id="resetPasswordClose"
              class="close position-absolute rounded-pill"
              @click="resetForgotModal()"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body p-5">
            <form autocomplete="on" v-on:submit.prevent="forgotPassword">
              <div class="form-group">
                <input
                  v-model="forgotPasswordEmail"
                  type="email"
                  required
                  class="form-control bg-white borderTextbox"
                  placeholder="Your email"
                  v-bind:class="
                    this.reg.test(this.forgotPasswordEmail)
                      ? 'is-valid'
                      : 'is-invalid'
                  "
                />
                <label class="text-danger" v-if="forgotPasswordError"
                  >*Email invalid</label
                >
              </div>

              <input
                type="submit"
                class="btn text-white text-capitalize bit-bold bg-yellow"
                value="Submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>

    <a
      href="#"
      id="OtpClick"
      data-dismiss="modal"
      data-toggle="modal"
      data-target="#otpModal"
      class="text-yellow d-none"
      >OTP</a
    >

    <!-- OTP Modal For Reset Password -->
    <div
      class="modal fade"
      id="otpModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otpModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title w-100" id="resetModalLabel">
              Reset Password
            </h2>
            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              id="ResetPasswordClose"
              @click="resetOtpForgotModal()"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body p-5">
            <form autocomplete="on" v-on:submit.prevent="otpForgotPassword">
              <div class="form-group">
                <label class="text-muted text-capitalize"
                  >Enter new password</label
                >
                <div class="input-group mb-3">
                  <input
                    :type="passwordFieldType"
                    autocomplete="new-password"
                    class="form-control"
                    placeholder="New Password"
                    aria-label="New Password"
                    aria-describedby="basic-addon1"
                    v-model="password"
                  />
                  <div class="input-group-prepend">
                    <span
                      @click="switchVisibility()"
                      class="input-group-text"
                      id="basic-addon1"
                      ><i
                        :class="
                          passwordFieldType == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>

                <label
                  v-if="has_minimum_lenth ? false : true"
                  class="text-muted"
                  >Password must be at least 6 characters</label
                >
              </div>

              <div class="form-group">
                <label class="text-muted text-capitalize"
                  >Confirm new password</label
                >
                <div class="input-group mb-3">
                  <input
                    :type="passwordFieldType1"
                    autocomplete="new-password"
                    class="form-control"
                    placeholder="Confirm New Password"
                    aria-label="Confirm New Password"
                    aria-describedby="basic-addon1"
                    v-model="resetConfirmPassword"
                  />
                  <div class="input-group-prepend">
                    <span
                      @click="switchVisibility(1)"
                      class="input-group-text"
                      id="basic-addon1"
                      ><i
                        :class="
                          passwordFieldType1 == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>

                <span
                  class="text-danger"
                  v-if="
                    password != resetConfirmPassword &&
                    resetConfirmPassword.length >= password.length &&
                    resetConfirmPassword != ''
                      ? true
                      : false
                  "
                  >*password does not match
                </span>
              </div>

              <div class="form-group">
                <p class="text-muted text-capitalize mb-0">Enter OTP</p>
                <OtpInput
                  ref="otpInput2"
                  input-classes="otp-input form-control text-center"
                  separator=""
                  :num-inputs="5"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-complete="handleOnComplete"
                  @on-change="handleOnChangeForgot"
                />
              </div>

              <input
                type="submit"
                :disabled="
                  resetConfirmPassword == password &&
                  has_minimum_lenth &&
                  otp.length == 5
                    ? false
                    : true
                "
                class="btn text-white text-capitalize bit-bold bg-yellow"
                value="Submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Signup Modal -->
    <div
      class="modal fade"
      id="signupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="signupModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content register">
          <div class="modal-header">
            <h2 class="modal-title w-100" id="signupModalLabel">
              Create an account
            </h2>
            <button
              type="button"
              id="registerClose"
              class="close position-absolute rounded-pill"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body p-5">
            <div
              class="alert alert-danger alert-dismissible"
              v-if="isRegistrationError"
            >
              Something went wrong
            </div>
            <div class="mb-4 row">
              <div class="my-2 col-lg-4 col-12">
                <GoogleLogin
                  id="google-oauth"
                  :params="params"
                  :onSuccess="onSuccess"
                  :onFailure="onFailure"
                  class="sso-btn google-bg"
                >
                  <span class="mr-2"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      aria-hidden="true"
                    >
                      <title>Google</title>
                      <g fill="none" fill-rule="evenodd">
                        <path
                          fill="#4285F4"
                          d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"
                        ></path>
                        <path
                          fill="#EA4335"
                          d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"
                        ></path>
                      </g>
                    </svg>
                  </span>
                  Sign up with Google
                </GoogleLogin>
              </div>
              <div class="my-2 col-lg-4 col-12">
                <button
                  id="fb-oauth"
                  type="button"
                  class="sso-btn facebook-bg"
                  @click.prevent="loginWithFacebook"
                >
                  <span class="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
                      />
                    </svg>
                  </span>
                  Sign up with Facebook
                </button>
              </div>
              <div class="my-2 col-lg-4 col-12">
                <button
                  type="button"
                  class="sso-btn apple-bg"
                  @click="loginWithApple"
                >
                  <span class="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="apple"
                      class="svg-inline--fa fa-apple fa-w-12"
                      role="img"
                      width="16"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                      />
                    </svg>
                  </span>
                  Sign up with Apple
                </button>
              </div>
            </div>
            <!-- <div class="continue__content">
              <span class="continue__text">Or Continue With Email Address</span>
            </div> -->

            <form autocomplete="on" v-on:submit.prevent="register" class="row">
              <div class="form-group col-lg-6 col-12">
                <input
                  type="text"
                  autocomplete="on"
                  v-bind:class="firstname != '' ? 'is-valid' : 'is-invalid'"
                  class="form-control"
                  placeholder="First Name"
                  required
                  v-model="firstname"
                />
              </div>

              <div class="form-group col-lg-6 col-12">
                <input
                  type="text"
                  autocomplete="on"
                  v-bind:class="lastname != '' ? 'is-valid' : 'is-invalid'"
                  class="form-control"
                  placeholder="Last Name"
                  required
                  v-model="lastname"
                />
              </div>

              <div class="form-group col-lg-6 col-12">
                <input
                  type="email"
                  autocomplete="on"
                  v-bind:class="
                    this.reg.test(this.email) ? 'is-valid' : 'is-invalid'
                  "
                  class="form-control"
                  placeholder="Email"
                  required
                  v-model="email"
                />
                <span class="text-danger" v-if="isEmailExists"
                  >*Email already exists</span
                >
              </div>

              <div class="form-group col-lg-6 col-12">
                <input
                  type="email"
                  autocomplete="on"
                  v-bind:class="
                    this.reg.test(this.confirmEmail) &&
                    this.email == this.confirmEmail
                      ? 'is-valid'
                      : 'is-invalid'
                  "
                  class="form-control"
                  placeholder="Confirm Email"
                  required
                  v-model="confirmEmail"
                />
                <span
                  class="text-danger"
                  v-if="
                    email != confirmEmail && email != '' && confirmEmail != ''
                  "
                  >*Email doesnt match</span
                >
              </div>
              <div class="mb-3 col-12">
                <div
                  class="row"
                  style="padding-right: 15px; padding-left: 15px"
                >
                  <!-- disabled="disabled" -->
                  <input
                    type="tel"
                    autocomplete="on"
                    readonly
                    value="+"
                    :class="validateIsd(mobileIsd) ? 'valid' : 'invalid'"
                    class="form-control bg-white col-1 inputLeft"
                    placeholder="+"
                  />
                  <input
                    type="tel"
                    autocomplete="on"
                    v-model="mobileIsd"
                    :class="validateIsd(mobileIsd) ? 'valid' : 'invalid'"
                    :maxlength="3"
                    required
                    class="form-control bg-white col-2 inputRight"
                    placeholder="ISD"
                  />
                  <input
                    type="number"
                    autocomplete="on"
                    v-model="mobileNo"
                    v-bind:class="
                      (mobileNo.length == 11 || mobileNo.length == 10) &&
                      validatePhone(mobileNo)
                        ? 'is-valid'
                        : 'is-invalid'
                    "
                    required
                    class="form-control col-9"
                    placeholder="Mobile"
                  />
                </div>
              </div>

              <div class="form-group col-lg-10 col-12"></div>

              <div class="form-group col-lg-6 col-12">
                <div class="input-group mb-3">
                  <input
                    :type="passwordFieldType"
                    autocomplete="new-password"
                    class="form-control"
                    placeholder="Password"
                    aria-label="Password"
                    v-model="password"
                    v-bind:class="has_minimum_lenth ? 'is-valid' : 'is-invalid'"
                  />
                  <div class="input-group-prepend">
                    <span
                      @click="switchVisibility()"
                      class="input-group-text"
                      id="basic-addon1"
                      ><i
                        :class="
                          passwordFieldType == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>

                <label
                  v-if="has_minimum_lenth ? false : true"
                  class="text-muted"
                  >Password must be at least 6 characters</label
                >
              </div>

              <div class="form-group col-lg-6 col-12">
                <div class="input-group mb-3">
                  <input
                    :type="passwordFieldType1"
                    autocomplete="new-password"
                    class="form-control"
                    placeholder="Confirm Password"
                    aria-label="Confirm Password"
                    aria-describedby="basic-addon1"
                    v-model="regConfirmPassword"
                    v-bind:class="
                      regConfirmPassword == password && regConfirmPassword != ''
                        ? 'is-valid'
                        : 'is-invalid'
                    "
                  />
                  <div class="input-group-prepend">
                    <span
                      @click="switchVisibility(1)"
                      class="input-group-text"
                      id="basic-addon1"
                      ><i
                        :class="
                          passwordFieldType1 == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>

                <span
                  class="text-danger"
                  v-if="
                    password != regConfirmPassword &&
                    regConfirmPassword.length >= password.length &&
                    regConfirmPassword != ''
                      ? true
                      : false
                  "
                  >*password does not match
                </span>
              </div>

              <div class="form-group col-md-6 col-12">
                <input
                  type="submit"
                  :disabled="
                    regConfirmPassword == password &&
                    this.reg.test(this.email) &&
                    this.validatePhone(this.mobileNo) &&
                    this.validateIsd(this.mobileIsd) &&
                    this.email == this.confirmEmail &&
                    has_minimum_lenth &&
                    (mobileNo.length == 10 || mobileNo.length == 11)
                      ? false
                      : true
                  "
                  class="btn text-white text-capitalize bit-bold bg-yellow"
                  value="Sign Up"
                />
              </div>
              <!-- <div class="text-center pt-25 col-12"> -->
              <div class="form-group col-md-6 col-12" style="padding-top: 10px">
                <p>
                  Already have an account?
                  <a
                    href="#"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#loginModal"
                    class="text-yellow"
                    @click="openLogin()"
                    >Sign in here</a
                  >
                </p>
              </div>
              <!-- </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/services/Login'
import TokenExpireLogin from '@/services/TokenExpireLogin'
import Vue from 'vue'
import store from '../store/index'
import OtpInput from '@bachdgvn/vue-otp-input'
import GoogleLogin from 'vue-google-login'
import initFbSDK from '@/services/FbOauth'
import { services, commercials } from '@/data'

export default {
  name: 'Toolbar',
  data() {
    return {
      websiteLink: 'https://primelaundry.co.uk',
      serviceLinks: services,
      commercialLinks: commercials,
      params: {
        client_id:
          '82094390670-c6q6dgu5cobv6idsd4chcln6rb87pgjh.apps.googleusercontent.com',
      },
      showloading: false,
      passwordFieldType: 'password',
      passwordFieldType1: 'password',
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      displayName: '',

      //Login
      username: '',
      password: '',

      //validation
      has_minimum_lenth: false,

      //Forgot Password
      forgotPasswordEmail: '',
      forgotPasswordEmailTemp: '',
      forgotPasswordError: false,
      resetConfirmPassword: '',
      otp: '',

      //Register
      isPostcodeSearch: false,
      // addressList:[],
      showAddressDiv: false,
      isValid: true,
      isError: false,
      firstname: '',
      lastname: '',
      email: '',
      confirmEmail: '',
      mobileIsd: '44',
      mobileNo: '',

      regConfirmPassword: '',
      isRegistrationError: false,
      isPasswordLength: null,
      //responseData:[],
      isEmailExists: false,
      isLogin: false,
      registerOTP: '',
      isSignUp: false,
      d_none: 'd-none',
      appleDetails: '',
    }
  },
  components: {
    OtpInput,
    // SocialLogins,
    GoogleLogin,
  },

  mounted() {
    initFbSDK()

    window.AppleID.auth.init({
      clientId: 'com.example.primelaundrysignin',
      scope: 'name email',
      redirectURI: process.env.VUE_APP_APPLE_REDIRECT_URL,
      state: 'origin:web',
      usePopup: true,
    })

    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      console.log(data, 'success')
    })
    document.addEventListener('AppleIDSignInOnFailure', () => {
      // console.log(error, 'error')
    })

    this.$root.$on('checkPassword', () => {
      console.log(document.getElementById('LoginClick').click())
    })

    if (
      localStorage.getItem('isLogin') == true ||
      localStorage.getItem('isLogin') == 'true'
    ) {
      this.isLogin = true
      const userData = this.$store.getters.getUser
      this.displayName = userData['firstname']
    } else {
      this.isLogin = false
    }
  },
  methods: {
    CallGTag() {
      var url = window.location.href
      var callback = function() {
        window.location = url
      }

      this.$gtag.reportConversion({
        send_to: 'AW-478064958/UHxACOCjxvYBEL7i-uMB',
        event_callback: callback, // the custom tag to be reported
      })

      // console.log("Gtag called" + callback);
    },

    validateIsd(Isd) {
      let regex = /^[0-9]{2}$/
      let regex1 = /^[0-9]{3}$/
      let regex2 = /^[0-9]{1}$/

      if (Isd.match(regex) || Isd.match(regex1) || Isd.match(regex2)) {
        return true
      } else {
        return false
      }
    },

    validatePhone(phone) {
      let regex = /^[0-9]{10}$/
      let regex1 = /^[0-9]{11}$/

      if (phone.match(regex) || phone.match(regex1)) {
        return true
      } else {
        return false
      }
    },

    async loginWithApple() {
      try {
        const response = await window.AppleID.auth.signIn()
        let token = response.authorization.id_token
        let data = this.parseJwt(token)
        console.log('data', data)
        this.firstName = ''
        this.lastName = ''
        this.email = data.email //data.email
        let providerName = 'apple'
        let providerId = data.sub
        let accessToken = token
        console.log(this.email)
        // if (this.isSignUp) {
        if (response.user != undefined) {
          console.log(response.user)
          console.log('response', this.email)
          this.firstName = response.user.name.firstName
          this.lastName = response.user.name.lastName

          if (this.firstName.length != 0 && this.lastName.length != 0) {
            this.ssoRegisterLogin(providerName, providerId, accessToken)
          }
        } else {
          this.ssoRegisterLogin(providerName, providerId, accessToken)
        }
        // }
      } catch (err) {
        console.log(err)
      }
    },
    parseJwt(token) {
      var id_token = token
      var base64Url = id_token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )

      return JSON.parse(jsonPayload)
    },
    loginWithFacebook() {
      window.FB.login(
        (response) => {
          if (response && response.authResponse) {
            // console.log("response", response.authResponse);
            window.FB.api(
              `/${response.authResponse.userID}`,
              (userResponse) => {
                if (userResponse) {
                  // console.log("userresponse", userResponse);

                  const userInfor = {
                    loginType: 'fb',
                    fb: {
                      auth: response.authResponse,
                      user: userResponse,
                      name: userResponse.first_name,
                    },
                  }
                  console.log('userInfo', userInfor)
                }
              },
              this.params
            )
            let accessToken = response.authResponse.accessToken
            window.FB.api(
              `/me`,
              {
                fields: ['id', 'name', 'email', 'picture'],
              },
              (res) => {
                let providerId = res.id
                this.firstName = res.name.split(' ')[0]
                this.lastName = res.name.split(' ')[1]
                this.email = res.email
                let providerName = 'facebook'
                // if (this.isSignUp) {
                this.ssoRegisterLogin(providerName, providerId, accessToken)
                // }
              }
            )
          }
        },
        { scope: 'email' }
      )
    },
    onSuccess(googleUser) {
      const user = googleUser.getBasicProfile()
      this.firstName = user.getGivenName()
      this.lastName = user.getName().split(' ')[1]
      this.email = user.getEmail()
      let providerId = user.getId()
      let accessToken = googleUser.getAuthResponse().id_token
      let providerName = 'google'

      this.ssoRegisterLogin(providerName, providerId, accessToken)
    },
    onFailure(err) {
      console.log(err, 'err')
    },
    onLogoutSuccess() {
      console.log('logout')
    },
    switchVisibility(id) {
      if (id == 1) {
        this.passwordFieldType1 =
          this.passwordFieldType1 === 'password' ? 'text' : 'password'
      } else {
        this.passwordFieldType =
          this.passwordFieldType === 'password' ? 'text' : 'password'
      }
    },

    OpenOldRegister() {
      document.getElementById('otpRegisterModal').click()
      document.getElementById('RegisterClick').click()

      this.registerOTP = ''
      this.$refs.otpInput.clearInput()
    },

    openLogin() {
      this.password = ''
      this.username = ''
    },

    openRegister() {
      this.firstname = ''
      this.lastname = ''
      this.email = ''
      this.confirmEmail = ''
      this.mobileNo = ''
      this.password = ''
      this.regConfirmPassword = ''
      this.isSignUp = true
      if (this.$session.exists()) {
        var userObjectSession = this.$session.get('userObject')
        this.firstname = userObjectSession['firstname']
        this.lastname = userObjectSession['lastname']
        this.email = userObjectSession['email']
        this.mobileNo = userObjectSession['contactNo']
      }
      this.email = ''
    },
    async login() {
      this.showloading = true
      const response = await Login.getLogin({
        username: this.username,
        password: this.password,
      })

      // console.log(response);

      if (response) {
        if (response.data.isSuccess) {
          this.setStoreData(response.data.data1, response.data.data2)

          localStorage.setItem('isLogin', true)

          this.$root.$emit('setCheckout')
          this.$root.$emit('changeLogin') //like this
          this.$root.$emit('changeLoginNav')
          this.$root.$emit('changeLoginMob')

          this.isLogin = true

          //Close Modal
          document.getElementById('close').click()

          //Clear input after login
          this.username = ''
          this.password = ''
          if (localStorage.getItem('refer-friend')) {
            localStorage.removeItem('refer-friend')
            this.$router.push('refer-friends')
          } else {
            localStorage.setItem('Refresh', true)
            // if (!localStorage.getItem("TokenExpire")) {
            //   this.$router.go();
            // }
          }

          this.$notify({
            title: 'Login In',
            message: 'Welcome to prime laundry',
            type: 'success',
          })
        } else {
          this.isError = true
          this.$notify.error({
            title: 'Incorrect details',
            message: 'Retry email id or password',
          })

          //Clear input after login
          this.username = ''
          this.password = ''
        }
      } else {
        this.$notify.error({
          title: 'Oops',
          message: 'Something went wrong',
        })

        //Clear input after login
        this.username = ''
        this.password = ''
      }
      this.showloading = false
    },

    async ssoRegisterLogin(providerName, providerId, accessToken) {
      this.showloading = true
      // let first_name = providerName ? firstName : this.firstname;
      // let last_name = providerName ? lastName : this.lastname;
      // let email = providerName ? emailId : this.email;
      let password = providerName ? '' : this.password
      const response = await Login.postCustomer({
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        mobileNo: '',
        address1: '',
        address2: '',
        postCode: '',
        plateform: 'Web',
        loginDetails: {
          username: this.email,
          password: password,
        },

        providerName: providerName,
        providerId: providerId,
        accessToken: accessToken,
      })

      var provider_name = this.$CryptoJS.AES.encrypt(
        providerName,
        Vue.prototype.$secretKey
      ).toString()

      var provider_id = this.$CryptoJS.AES.encrypt(
        providerId,
        Vue.prototype.$secretKey
      ).toString()

      var access_token = this.$CryptoJS.AES.encrypt(
        accessToken,
        Vue.prototype.$secretKey
      ).toString()

      const loginType = {
        providerName: provider_name,
        providerId: provider_id,
        accessToken: access_token,
      }
      this.checkResponse(response)
      store.commit('setLoginType', loginType)
      this.showloading = false
    },

    async register() {
      if (this.mobileIsd == '') {
        this.mobileIsd = '44'
      }
      const response = await Login.insertCustomer({
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        mobileNo: '+' + this.mobileIsd + '-' + this.mobileNo,
        address1: '',
        address2: '',
        postCode: '',
        plateform: 'Web',
        loginDetails: {
          username: this.email,
          password: this.password,
        },
      })
      this.checkResponse(response)
    },

    async checkResponse(response) {
      if (response) {
        //Registration succesfull
        if (response.data.isSuccess && response.data.statusCode != 3) {
          this.isRegistrationError = false
          this.isLogin = true

          this.setStoreData(response.data.data2, response.data.data1)
          document.getElementById('registerClose').click()
          document.getElementById('close').click()
          //Clear all inputs
          ;(this.firstname = ''),
            (this.lastname = ''),
            (this.email = ''),
            (this.mobileNo = ''),
            (this.password = ''),
            (this.regConfirmPassword = ''),
            //Close Modal
            // document.getElementById("registerClose").click();

            localStorage.setItem('isLogin', true)

          this.$root.$emit('setCheckout')
          this.$root.$emit('changeLogin')
          this.$root.$emit('changeLoginNav')
          this.$root.$emit('changeLoginMob')
          this.$notify({
            // title: "Registered",
            message: 'Welcome to prime laundry',
            type: 'success',
          })

          localStorage.setItem('Refresh', true)
          // let checkoutStaus = localStorage.getItem("setcheckout");

          // if (!checkoutStaus) {
          //   // if user directly login then after login page refresh
          //   this.$router.go();
          // }
        }
        //Registration failed
        else {
          if (response.data.statusCode == 3) {
            //Email already exists
            this.isEmailExists = true
            this.$notify.error({
              title: 'Email already exists',
              message: 'Please provide another email',
            })

            this.isRegistrationError = false
          } else {
            this.$notify.error({
              title: 'Oops',
              message: 'Something went wrong',
            })
          }
        }
      } else {
        if (localStorage.getItem('TokenExpire')) {
          const response = await TokenExpireLogin.tokenExpireLogin()
          if (response != undefined) {
            if (response.data.isSuccess) {
              this.checkResponse()
            }
          } else {
            this.$root.$emit('checkPassword')
          }
        } else {
          this.$notify.error({
            title: 'Oops',
            message: 'Something went wrong',
          })
        }
      }
    },

    logOut() {
      // let referFriend=localStorage.getItem("refer-friend") ;
      // if(referFriend){
      //   this.$router.go();
      // }
      localStorage.removeItem('token')
      localStorage.clear()
      localStorage.setItem('isLogin', false)
      this.isLogin = false

      this.$root.$emit('changeLogin')
      this.$root.$emit('changeLoginNav')
      this.$root.$emit('changeLoginMob', false)
      this.$router.go()
    },

    async forgotPassword() {
      this.showloading = true
      this.forgotPasswordEmailTemp = this.forgotPasswordEmail
      const response = await Login.forgotPassword({
        username: this.forgotPasswordEmail,
      })

      if (response) {
        if (response.data.isSuccess) {
          document.getElementById('resetPasswordClose').click()
          document.getElementById('OtpClick').click()
        } else {
          this.forgotPasswordError = true
        }
      } else {
        if (localStorage.getItem('TokenExpire')) {
          const response = await TokenExpireLogin.tokenExpireLogin()
          if (response != undefined) {
            if (response.data.isSuccess) {
              this.forgotPassword()
            }
          } else {
            this.$root.$emit('checkPassword')
          }
        } else {
          this.$notify.error({
            title: 'Oops',
            message: 'Something went wrong',
          })
        }
      }

      this.showloading = false
    },

    async otpForgotPassword() {
      this.showloading = true
      const response = await Login.changePassword({
        username: this.forgotPasswordEmailTemp,
        newPassword: this.password,
        otp: this.otp,
      })

      if (response) {
        if (response.data.isSuccess) {
          this.$notify({
            title: 'Successful',
            message: 'New Password Set',
            type: 'success',
          })

          this.password = ''
          this.resetConfirmPassword = ''
          this.otp = ''
          document.getElementById('ResetPasswordClose').click()

          this.forgotPasswordEmail = ''
          this.forgotPasswordEmailTemp = ''
        } else {
          this.$notify.error({
            title: 'Incorrect OTP',
            message: 'Enter correct OTP',
          })
        }
      } else {
        if (localStorage.getItem('TokenExpire')) {
          const response = await TokenExpireLogin.tokenExpireLogin()
          if (response != undefined) {
            if (response.data.isSuccess) {
              this.otpForgotPassword()
            }
          } else {
            this.$root.$emit('checkPassword')
          }
        } else {
          this.$notify.error({
            title: 'Oops',
            message: 'Something went wrong',
          })
        }
      }

      this.showloading = false
    },
    resetOtpForgotModal() {
      this.password = ''
      this.resetConfirmPassword = ''
      this.otp = ''
    },

    resetForgotModal() {
      this.forgotPasswordEmail = ''
    },

    handleOnComplete(value) {
      this.otp = value
    },
    handleOnChangeForgot(value) {
      this.otp = value
    },
    handleOnCompleteRegister(value) {
      this.registerOTP = value
    },

    setStoreData(token, user) {
      this.displayName = user['firstname']

      var emailEncrpt = this.$CryptoJS.AES.encrypt(
        user['secondaryEmail'], // here i changed email to secondaryEmail
        Vue.prototype.$secretKey
      ).toString()

      var passwordEncrypt = this.$CryptoJS.AES.encrypt(
        this.password.toString(),
        Vue.prototype.$secretKey
      ).toString()

      //Set data in store
      const userJson = {
        firstname: user['firstname'],
        lastname: user['lastname'],
        mobileNo: user['mobileNo'],
        password: passwordEncrypt,
        address1: user['address1'],
        address2: user['address2'],
        email: emailEncrpt,
        // secondaryEmail:emailEncrpt2
        id: this.$CryptoJS.AES.encrypt(
          user['id'].toString(),
          Vue.prototype.$secretKey
        ).toString(),
        // mobileNo: user["mobileNo"].replace("+41-", ""),
        postCode: user['postCode'],
        referFriendCode: user['referFriendCode'],
        totalOrder: user['totalOrder'],
      }

      const encryptedToken = this.$CryptoJS.AES.encrypt(
        token.toString(),
        Vue.prototype.$secretKey
      ).toString()

      store.commit('setUser', userJson)
      store.commit('setToken', encryptedToken) //encryptedToken
    },
  },
  watch: {
    password() {
      this.has_minimum_lenth = this.password.length >= 6
    },
  },
}
</script>

<style>
body {
  font-family: 'Inter', sans-serif;
}
.et-navbar .nav-links ul li a {
  font-size: 14px;
  letter-spacing: 0.5px;
}
.bookNow {
  padding: 10px 22px;
  border-radius: 24px;
}
#borderLeft {
  border-left: 1px solid #dedee0;
}

.modal .close {
  right: 23px;
  top: 21px;
}
.p-5 {
  padding: 1rem 3rem !important;
}
.register .modal-body {
  position: relative;
  padding: 20px;
  height: 494px;
  overflow-y: scroll;
}
.floatRight {
  float: right;
  margin-top: 20px;
}
@media (max-width: 480px) {
  .modal .close {
    top: 21px;
  }
}
.loginModal {
  display: flex;
}

@media screen and (max-width: 767px) {
  .logo {
    margin-left: 11px;
  }
  .rightPanel {
    width: 0;
  }
  #LoginClick,
  #borderLeft {
    display: none;
  }
  /* .bookNow span{
    margin-top:0;
    padding: 8px;
  } */
  /* .socialLogo{
    display: block;
    width:100%;
  } */
  .loginModal,
  .socialLogo {
    display: block;
  }
  p {
    text-align: center;
  }
  .loginForgot {
    margin: 0;
    text-align: center;
  }
  .registerClick {
    margin-top: 20px;
    text-align: center;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .responsiveButton {
    padding-right: 80px;
    padding-top: 2px;
  }
}
.btn-hover:hover {
  background-color: #17b0f3;
}
.continue__content {
  position: relative;
  margin-top: 1.2rem;
  text-align: center;
  margin-bottom: 1.2rem;
}

.continue__content::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  width: 100%;
  transform: translateY(-50%);
  line-height: inherit;
  border-top: 1px solid #d0d5da;
}

.continue__text {
  position: relative;
  z-index: 2;
  padding: 0 0.5rem;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
  color: #81898f;
  background-color: #fff;
}

.sso-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 2px;
  margin-right: 2px; */
  width: 100%;
  padding: 10px 10px;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 2px rgba(0, 0, 0, 0.06);
}

.sso-btn:focus,
.sso-btn:hover {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 4px rgba(0, 0, 0, 0.06);
}

.google-bg {
  background-color: #fff;
  color: #6d6a6a;
}

.facebook-bg {
  background-color: #4867aa;
  color: #fff;
}

.apple-bg {
  background-color: #000;
  color: #fff;
}
</style>

<style scoped>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

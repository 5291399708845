<template>
  <!-- Login Menu -->
  <div
    class="col-sm-4 col-lg-2 pl-0 d-none d-sm-block d-lg-block"
    v-if="isLoggged == true || isLoggged == 'true'"
  >
    <div style="top: 10rem"><!--p-4-->
      <!-- Sidebar Widget -->
      <div class="side-widget" style="width:200px">
        <div class="widget-content">
          <div
            class="order pt-3 d-inline-flex align-items-center"
            style="font-size: 17px; border-bottom: initial"
          >
            <i class="rounded-pill mr-1 icon-bg bg-white">
              <img
                src="/static/images/shopping-cart.svg"
                class="f-icon"
                alt="My Orders-Prime Laundry"
              />
            </i>
            <router-link to="/Orders" style="color:black"
              ><a class="pl-1">
                My Orders</a
              ></router-link
            >
          </div>

          <div
            class="order pt-3 d-inline-flex align-items-center"
            style="font-size: 17px; border-bottom: initial"
          >
            <i class="rounded-pill mr-1 icon-bg bg-white">
              <img
                src="/static/images/user (2).svg"
                class="f-icon"
                alt="My Account-Prime Laundry"
              />
            </i>
            <router-link to="/Account" style="color:black"
              ><a class="pl-1"
                >Account</a
              ></router-link
            >
          </div>

          <div
            class="order pt-3 d-inline-flex align-items-center"
            style="font-size: 17px; border-bottom: initial"
          >
            <i class="rounded-pill mr-1 icon-bg bg-white">
              <img
                src="/static/images/good.svg"
                class="f-icon"
                alt="Refer Friends-Prime Laundry"
              />
            </i>
            <router-link to="/refer-friends" style="color:black"
              ><a class="pl-1"
                >Refer Friends</a
              ></router-link
            >
          </div>

          <div
            class="order pt-3 d-inline-flex align-items-center"
            style="font-size: 17px; border-bottom: initial"
          >
            <i class="rounded-pill mr-1 icon-bg bg-white">
              <img
                src="/static/images/lock.svg"
                class="f-icon"
                alt="Change Password-Prime Laundry"
              />
            </i>
            <router-link to="/ChangePassword" style="color:black"
              ><a class="pl-1"
                >Change Password</a
              ></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerNavigation",
  data() {
    return {
      isLoggged: false,
    };
  },
  mounted() {
    this.isLoggged = localStorage.getItem("isLogin");

    this.$root.$on("changeLoginNav", () => {
      this.isLoggged = localStorage.getItem("isLogin");
    });
  },
};
</script>
<style>
.icon-bg{
  box-shadow: none !important;
}
</style>

<template>
  <div>
    <!-- Back To Top -->
    <div id="backTop"><i aria-hidden="true" class="fa fa-arrow-up"></i></div>
    <Toolbar> </Toolbar>
  </div>
</template>

<script>
import Toolbar from "@/core/toolbar.vue";

export default {
  name: "navigation2",
  data() {
    return {
      isLogged: false,
    };
  },
  components: {
    Toolbar,
  },
  mounted() {
    this.isLogged = localStorage.getItem("isLogin");

    this.$root.$on("changeLoginMob", () => {
      this.isLogged = localStorage.getItem("isLogin");
    });
  },

  methods: {
    CallGTag() {
      var url = window.location.href;
      var callback = function() {
        window.location = url;
      };

      this.$gtag.reportConversion({
        send_to: "AW-478064958/UHxACOCjxvYBEL7i-uMB",
        event_callback: callback, // the custom tag to be reported
      });

    },
  },
};
</script>
<style>
.et-topbar{
  background-color: #FFFFFF;
}
</style>
import Api from '@/services/Api';

export default {
  getAllAddress(postcode) {
    return Api()
      .get(
        'https://api.getAddress.io/find/' +
          postcode +
          '?api-key=5pn8Rb51YkK0KEKoxeRfcg25086'
        // "?api-key=LMd1PfWMZUaUR_o638Cm9w25086"
      )
      .catch(function(error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
  },
};

/* eslint-disable */

export default function initFbSDK() {
  window.fbAsyncInit = () => {
    FB.init({
      appId: "2708339415979032",
      cookie: true,
      xfbml: true,
      version: "v12.0",
    });

    FB.AppEvents.logPageView();
  };
  (function(d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;

    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
}

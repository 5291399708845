import Api from "@/services/Api";

export default {
  async checkout(sessionId) {
    let stripe = window.Stripe(
      process.env.VUE_APP_STRIP_KEY
    );
    //"pk_live_51HvnQcLd90slTD01Bwfqr8DyuI6vjGX2wwBObgGRbFv4GwuGL5Ti4YXu2eRlbUrXuD8ZxiPS8jTwPBtzmDcJYTmU00pyb9d8dq"
    //"pk_test_51HvnQcLd90slTD01oOoQm8BauUty54ta4rIjrkorrsOlR9eqAARfgw4mFb2xMESrHROjbHzEwDfHLb5UvZESBPW800bufRvQVg"
    stripe.redirectToCheckout({
      sessionId: sessionId,
    });
  },

  confirmPayment(params) {
    return Api()
      .post("ConfirmPayment", params)
      .catch(function(error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },
};
